import Portal from '@burstware/react-native-portal';
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useRoute } from '@react-navigation/native';
import { useFirestoreDocumentData, useFirestoreDocumentDeletion, useFirestoreDocumentMutation, useFirestoreQuery } from "@react-query-firebase/firestore";
import * as DocumentPicker from 'expo-document-picker';
import { DocumentReference, Timestamp, addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getFirestore, orderBy, query, serverTimestamp, where } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes, uploadString } from "firebase/storage";
import { clamp, get } from "lodash";
import moment from "moment";
import { shuffle } from "radash";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ActivityIndicator, ScrollView, StyleSheet, TextInput, TouchableOpacity, View } from "react-native";
import { Drawer } from 'react-native-drawer-layout';
import { useMediaQuery } from "react-responsive";
import AuthContext from "../context/AuthContext";
import autoCalification from '../utils/autoCalification';
import Button from "./common/Button";
import MediaViewerManager from "./common/MediaViewerManager";
import Modal from "./common/Modal";
import SecureImage from "./common/SecureImage";
import SecurePDF from "./common/SecurePDF";
import SecureVideo from "./common/SecureVideo";
import StyledText from "./common/StyledText";
import ControledVideo from "./smartComponents/ControledVideo";


const ContentViewer = ({ content, courseId, tenantId, teachers, course }) => {

    const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

    const { data, isLoading, isError } = useFirestoreDocumentData([{ id: content?.ref?.id }], content?.ref, {}, {
        enabled: !!content?.ref?.id,
        select: (d) => {
            if (d) {
                return ({ ...d, ...content })
            } else {
                return null
            }
        }
    })


    if (isLoading) {
        return (
            <View style={{ flex: 1, minHeight: 400, alignItems: "center", justifyContent: "center" }}>
                <ActivityIndicator />
            </View>
        );
    }

    if (isError) {
        return (
            <View style={[styles.shadow, { padding: 10, paddingVertical: 100, paddingHorizontal: 15, backgroundColor: "white", borderRadius: 7 }]}>
                <StyledText style={{ textAlign: "center", fontFamily: "TitleWide", fontWeight: "bold", opacity: .2, fontSize: 24 }}>Ocurrió un error cargando el contenido</StyledText>
            </View>
        )
    }

    if (!data) return (
        <View style={[styles.shadow, { padding: 10, paddingVertical: 100, paddingHorizontal: 15, backgroundColor: "white", borderRadius: 7 }]}>
            <StyledText style={{ textAlign: "center", fontFamily: "TitleWide", fontWeight: "bold", opacity: .2, fontSize: 24 }}>El contenido seleccionado no se encuentra disponible o no existe</StyledText>
        </View>
    )
    const isVertical = ((!data?.deadlines || data?.deadlines?.length === 0) && !data.content) || data?.contentType === "EMBEDDED" || data?.contentType === "VIDEO" || isPhone
    return (
        <>
            <View style={{ paddingTop: 20, flexDirection: isVertical ? "column" : "row" }}>
                <View style={{ flex: isVertical ? null : 1, maxHeight: isVertical ? "auto" : 600 }}>
                    {data?.deadlines && data?.deadlines?.length > 0 && data?.contentType === "VIDEO" ?
                        <ControledVideo
                            uri={data?.media}
                            advanceRef={course?.advance?.ref}
                            content={content}
                            contentId={content.id}
                            style={{ borderRadius: 7, backgroundColor: "gainsboro", width: "100%", aspectRatio: 1, minHeight: 400 }}
                        />
                        :
                        data?.contentType === "EMBEDDED" || data?.contentType === "VIDEO" ?
                            <MediaViewerManager
                                type={data?.contentType}
                                url={data?.embedded || data?.media}
                                cover={data?.image}
                                tracking={false}
                                videoProps={{
                                    useNativeControls: false,
                                    positionCallback: () => null,
                                    onPlaybackStatusUpdate: () => null
                                    // positionMillis: lastWatched,
                                }}
                            />
                            : data?.contentType === "DOCUMENT" ?
                                data?.media ?
                                    <SecurePDF uri={data?.media} style={{ minHeight: 400, width: "100%", backgroundColor: "gainsboro", borderRadius: 7 }} />
                                    : <SecureImage placeholder={"https://brandemia.org/sites/default/files/inline/images/03-rfef-imagenes-brandemia-blog_0.jpg"} uri={data?.image} style={{ borderRadius: 7, backgroundColor: "gainsboro", width: "100%", aspectRatio: 1, minHeight: 400 }} />
                                :
                                data?.contentType === "VIDEO" ?
                                    <SecureVideo uri={data?.media} style={{ borderRadius: 7, backgroundColor: "gainsboro", width: "100%", aspectRatio: 1, minHeight: 400 }} />
                                    :
                                    data?.contentType === "UPLOAD" ?
                                        (data?.media ?
                                            <SecurePDF uri={data?.media} style={{ minHeight: 400, width: "100%", backgroundColor: "gainsboro", borderRadius: 7 }} />
                                            :
                                            <SecureImage placeholder={"https://brandemia.org/sites/default/files/inline/images/03-rfef-imagenes-brandemia-blog_0.jpg"} uri={data?.image} style={{ borderRadius: 7, backgroundColor: "gainsboro", width: "100%", aspectRatio: 1, minHeight: 400 }} />)
                                        :
                                        <SecureImage placeholder={"https://brandemia.org/sites/default/files/inline/images/03-rfef-imagenes-brandemia-blog_0.jpg"} uri={data?.image} style={{ borderRadius: 7, backgroundColor: "gainsboro", width: "100%", aspectRatio: 1, minHeight: 400 }} />
                    }

                </View>

                <View style={{ flex: isPhone ? null : 1, marginTop: isPhone ? 15 : 0, paddingHorizontal: isPhone ? 0 : 15 }}>

                    {(data?.content || data?.contentType === "LINK" || data?.contentType === "EXAM") ?
                        <View style={[styles.shadow, { padding: 10, paddingHorizontal: 15, backgroundColor: "white", borderRadius: 7 }]}>
                            <View style={{ marginBottom: 10 }}>
                                <StyledText style={{ fontFamily: "Title", fontWeight: "bold", fontSize: 30 }}>{"Detalles"}</StyledText>
                            </View>

                            <div dangerouslySetInnerHTML={{ __html: data?.content || "<p></p>" }} style={{ fontFamily: "Regular" }} />

                            {data?.contentType === "LINK" ? <View style={{ alignItems: "flex-start", marginTop: 10 }}> <Button label="Enlace al Contenido" onPress={() => window.open(data?.link)} height="35px" round={7} /> </View> : null}
                            {data?.contentType === "EXAM" && (
                                <>
                                    <View style={{ marginTop: 0, flexDirection: "row", flexWrap: "wrap" }}>
                                        {data?.contentLength ? <View style={{ width: "33%" }}>
                                            <StyledText>Duración</StyledText>
                                            <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>{data?.contentLength} min</StyledText>
                                        </View> : null}
                                        <View style={{ width: "33%" }}>
                                            <StyledText>Intentos Max.</StyledText>
                                            <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>{data?.maxTries}</StyledText>
                                        </View>
                                        <View style={{ width: "33%" }}>
                                            <StyledText>Preguntas</StyledText>
                                            <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>{data?.numberOfQuestions}</StyledText>
                                        </View>
                                    </View>
                                    <View style={{ marginTop: 10, flexDirection: "row", flexWrap: "wrap" }}>
                                        <View style={{ width: "33%" }}>
                                            <StyledText>Acierto</StyledText>
                                            <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>{data?.correctValue}</StyledText>
                                        </View>
                                        <View style={{ width: "33%" }}>
                                            <StyledText>Sin Contestar</StyledText>
                                            <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>{data?.noAnswerValue}</StyledText>
                                        </View>
                                        <View style={{ width: "33%" }}>
                                            <StyledText>Fallo</StyledText>
                                            <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>{data?.wrongValue}</StyledText>
                                        </View>
                                    </View>
                                </>
                            )}
                        </View>
                        : null
                    }
                    {(data?.deadlines && data?.deadlines?.length > 0) ?
                        <DeadlinesViewer
                            course={course}
                            content={data}
                            deadlines={data?.deadlines}
                            contentType={data?.contentType}
                            contentId={content.id}
                            courseId={courseId}
                            tenantId={tenantId}
                            teachers={teachers}
                        />
                        : null}
                </View>
            </View>
        </>
    )
}
export default ContentViewer

const DeadlinesViewer = ({ content, deadlines, contentType, contentId, courseId, tenantId, course, teachers }) => {


    const [examModal, setExamModal] = useState(false);
    const { userData } = useContext(AuthContext)
    const db = getFirestore()


    const advanceRef = doc(db, `tenants/${tenantId}/courses/${courseId}/courseavs/${userData?.id}`)
    const [approved, setApproved] = useState(false)

    const advancesRef = query(collection(db, `${advanceRef?.path}/contents`), where("contentId", "==", contentId))
    const { data, error, isLoading } = useFirestoreQuery(['advanceContent', contentId], advancesRef, { subscribe: true }, {
        refetchOnMount: "always",
        select: (data) => data?.docs?.map((el) => ({ ...el?.data(), deadlineId: el?.data()?.deadlineId ? el?.data()?.deadlineId : el?.id })),
        onSuccess: (data) => data?.some((el) => el?.status === "APPROVED") && setApproved(true),
        onError: e => console.log(e)
    })

    if (contentType === "EXAM") {
        return (
            <>
                <View style={[styles.shadow, { marginTop: 20, padding: 10, paddingHorizontal: 15, backgroundColor: "white", borderRadius: 7 }]}>
                    <View style={{ marginBottom: 10 }}>
                        <StyledText style={{ fontFamily: "Title", fontWeight: "bold", fontSize: 30 }}>Mi Examen</StyledText>
                    </View>
                    {deadlines.map((el, i) => {
                        if (!el.start || !el.end) return null
                        const canUploadNow = canUpload(el.start, el.end)
                        return <DeadlineExam course={course} teachers={teachers} key={i} content={content} attempts={content.maxTries} openExamCallback={setExamModal} el={el} examRef={content.ref} canUploadNow={canUploadNow} deadlineId={el.id} contentId={contentId} tenantId={tenantId} advanceRef={advanceRef} />
                    })}
                </View>

                {examModal && <ExamComponent course={course} teachers={teachers} tenantId={tenantId} content={content} advanceRef={examModal.ref} advance={examModal} preview={examModal?.preview} setExamModal={setExamModal} />}
            </>
        )
    } else {
        return (
            <View style={[styles.shadow, { marginTop: 20, padding: 10, paddingHorizontal: 15, backgroundColor: "white", borderRadius: 7 }]}>

                <View style={{ marginBottom: 10 }}>
                    <StyledText style={{ fontFamily: "Title", fontWeight: "bold", fontSize: 30 }}>Mi Tarea</StyledText>
                </View>

                {deadlines.map((el, i) => {
                    if (!el.start || !el.end) return null
                    const canUploadNow = canUpload(el.start, el.end, approved)
                    const deadline = data?.find((x) => x?.deadlineId === el?.id)
                    return (
                        <DeadlineUpload
                            key={i}
                            el={el}
                            content={content}
                            course={course}
                            teachers={teachers}
                            enabled={content.deadlinesUploads}
                            canUploadNow={canUploadNow}
                            deadlineId={el.id}
                            contentId={contentId}
                            tenantId={tenantId}
                            advanceRef={advanceRef}
                            data={deadline}
                        />
                    )
                })}
            </View>
        )
    }
}

const DeadlineHistory = forwardRef(({ advanceRef }: { advanceRef: DocumentReference }, refComp) => {
    const db = getFirestore()
    const storage = getStorage()
    const [open, setOpen] = useState(false)

    const historyRef = query(collection(db, `${advanceRef?.path}/taskhistory`), orderBy("createdAt", "desc"))


    const { data, error, isLoading } = useFirestoreQuery(['taskHistory', advanceRef?.id], historyRef, {
    }, {
        enabled: open,
        refetchOnMount: "always",
        select: (data) => data?.docs?.map((el) => ({ ...el?.data(), id: el?.id })),
        onError: e => console.log(e)
    })


    useImperativeHandle(refComp, () => ({
        open: () => setOpen(true),
        close: () => setOpen(false)
    }), [open])

    if (isLoading) {
        return (
            <View style={{ flex: 1, minHeight: 400, alignItems: "center", justifyContent: "center" }}>
                <ActivityIndicator />
            </View>
        )
    }

    if (error) {
        return (
            <View style={[styles.shadow, { padding: 10, paddingVertical: 100, paddingHorizontal: 15, backgroundColor: "white", borderRadius: 7 }]}>
                <StyledText style={{ textAlign: "center", fontFamily: "TitleWide", fontWeight: "bold", opacity: .2, fontSize: 24 }}>Ocurrió un error cargando el historial</StyledText>
            </View>
        )
    }

    if (!open) return null

    return (
        <Portal>
            <Drawer
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                renderDrawerContent={() => {
                    return (
                        <ScrollView contentContainerStyle={{ padding: 15 }}>
                            <View >
                                {data?.length === 0 ? (
                                    <View style={{ padding: 10, backgroundColor: "#f3f3f3", alignItems: "center", flex: 1, borderRadius: 5 }}>
                                        <StyledText>{"No History"} </StyledText>
                                    </View>
                                ) : (
                                    <View style={{ gap: 10 }}>
                                        {data?.map((el, i) => {
                                            const fileUri = get(el, "lastUpdate.file.uri", "")
                                            const action = get(el, "lastUpdate.action", "")
                                            const type = get(el, "lastUpdate.type", "")
                                            const grade = get(el, "lastUpdate.grade", "")
                                            const status = get(el, "lastUpdate.status", "")
                                            const comments = get(el, "lastUpdate.comments", "")
                                            const createdAt = get(el, "lastUpdate.createdAt", "")
                                            return (
                                                <View key={i} style={{ gap: 10 }}>

                                                    <View style={{ flexDirection: "row", gap: 10 }}>
                                                        <SecureImage placeholder={""} uri={get(el, "lastUpdate.image", "")} style={{ width: 30, height: 30, borderRadius: 15 }} />
                                                        <View>

                                                            <StyledText style={{ fontWeight: "bold" }}>
                                                                {get(el, "lastUpdate.name", "")} {get(el, "lastUpdate.surname", "")}
                                                            </StyledText>
                                                            <StyledText>
                                                                {fixDate(createdAt)?.toDateString()} - {fixDate(createdAt)?.toLocaleTimeString()}
                                                            </StyledText>
                                                        </View>
                                                    </View>

                                                    <View>
                                                        <StyledText>
                                                            {action === "upload" ? "Archivo Cargado" : action === "delete" ? "Archivo Eliminado" : "Corrección"}
                                                        </StyledText>
                                                    </View>

                                                    <View style={{ padding: 5, backgroundColor: "#f3f3f3", borderRadius: 5 }}>
                                                        {type === "FILE" ?
                                                            fileUri ?
                                                                <View style={{ flexDirection: "row", marginBottom: 0, alignItems: "center", justifyContent: "space-between" }}>
                                                                    <View style={{ flex: 1, paddingRight: 10 }}>
                                                                        <StyledText numberOfLines={1} style={{ fontSize: 16 }}>{fileUri?.split("/").pop().split(".").slice(0, -1).join("")}</StyledText>
                                                                    </View>
                                                                    {action !== "delete" ?
                                                                        <Button
                                                                            icon="eye-outline"
                                                                            color="#f2f2f2"
                                                                            paddingHorizontal={15}
                                                                            round={5}
                                                                            height={35}
                                                                            width="35px"
                                                                            style={{ marginRight: 5 }}
                                                                            onPress={() => {
                                                                                var windowReference = window.open();
                                                                                const fileRef = ref(storage, fileUri)

                                                                                getDownloadURL(fileRef).then(res => {
                                                                                    windowReference.location = res
                                                                                }).catch(err => alert("Error obteniendo el archivo"))
                                                                            }} />
                                                                        : null
                                                                    }
                                                                </View>
                                                                :
                                                                <StyledText>
                                                                    No File
                                                                </StyledText>
                                                            :
                                                            <View >
                                                                {grade ?
                                                                    <StyledText>
                                                                        Nota: <strong>{grade}</strong>
                                                                    </StyledText> : null}
                                                                <StyledText>
                                                                    Status: <strong>{status}</strong>
                                                                </StyledText>
                                                                {comments ? <StyledText>
                                                                    Comments: <strong><div dangerouslySetInnerHTML={{ __html: comments }} /></strong>
                                                                </StyledText> : null}

                                                            </View>
                                                        }
                                                    </View>
                                                </View>

                                            )
                                        })}
                                    </View>
                                )}
                            </View>
                        </ScrollView>
                    )
                }}
            >
                <></>
                {/* <Button
                    onPress={() => setOpen((prevOpen) => !prevOpen)}
                    title={`${open ? 'Close' : 'Open'} drawer`}
                /> */}
            </Drawer>

        </Portal>
    )


})

const DeadlineExam = ({ el, content, examRef, tenantId, attempts, canUploadNow, deadlineId, contentId, advanceRef, openExamCallback, teachers, course }) => {
    // const p = {
    //     createdAt: serverTimestamp(),
    //     updatedAt: serverTimestamp(),
    //     contentId,
    //     deadlineId,
    //     attempt: 0,
    //     observations: "",
    //     grade: null,
    //     state: "PENDINGREVIEW",
    //     examRef: "ref",
    //     exam: [
    //         {
    //             name: "Archivo 1",
    //             createdAt: new Date(),
    //             updatedAt: new Date(),
    //             uri: ""
    //         }
    //     ],
    // }

    const db = getFirestore()
    const [loading, setLoading] = useState(false)
    const { userData } = useContext(AuthContext)
    // const course = useContext(CourseContext)

    const coordinators = get(course, "teachers", [])

    const deadlineAttemptsRef = query(collection(db, `${advanceRef?.path}/contents`), where("contentId", "==", contentId), where("deadlineId", "==", deadlineId), orderBy("createdAt", "desc"))
    const deadlineCollectionRef = collection(db, `${advanceRef?.path}/contents`)
    const { data } = useFirestoreQuery([deadlineId, contentId], deadlineAttemptsRef, { subscribe: true }, {
        refetchOnMount: "always",
        select: (query) => query?.docs?.map((q) => ({ ...q.data(), ref: q.ref, id: q.id }) || []),
        onError: e => console.log(e)
    })

    const startExam = async () => {

        setLoading(true)
        try {
            addDoc(deadlineCollectionRef, {
                correct: 0,
                grade: 0,
                percentage: 0,
                total: 0,
                wrong: 0,
                maxTries: content.maxTries,
                numberOfQuestions: content.numberOfQuestions,
                contentLength: content.contentLength,
                correctValue: content.correctValue,
                wrongValue: content.wrongValue,
                noAnswerValue: content.noAnswerValue,
                status: "IN_PROGRESS",
                contentType: "EXAM",
                examRef: examRef,
                tenantId: tenantId,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                endTime: null,
                contentId,
                deadlineId,
                attempt: (data?.length || 0) + 1,
                observations: "",
                questions: [],
                teachers,
                coordinators,
                userName: userData.name + " " + userData.surname,
                userImage: userData.image,
                userId: userData.id,
                newUpdate: true,
                courseId: course.id,
                courseName: course.name,
                courseTag: course.tag,
                name: content.title,
            }).then(res => {
                openExamCallback({
                    correct: 0,
                    duration: 0,
                    grade: 0,
                    percentage: 0,
                    total: 0,
                    wrong: 0,
                    maxTries: content.maxTries,
                    numberOfQuestions: content.numberOfQuestions,
                    contentLength: content.contentLength,
                    correctValue: content.correctValue,
                    wrongValue: content.wrongValue,
                    noAnswerValue: content.noAnswerValue,
                    status: "IN_PROGRESS",
                    examRef: examRef,
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                    endDate: null,
                    contentId,
                    deadlineId,
                    attempt: (data?.length || 0) + 1,
                    observations: "",
                    questions: [],
                    ref: res
                })
                setLoading(false)
            })
        } catch (err) {
            alert("Error al iniciar el examen, no se pudo crear el documento de seguimiento")
            setLoading(false)
            console.log(err)
        }
    }

    const removeExam = async (exam) => {
        try {
            await deleteDoc(exam)
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <View style={{ marginBottom: 15 }}>
            <View style={{ opacity: canUploadNow ? 1 : .3 }}>
                <StyledText style={{ fontSize: 16 }}>
                    {`${el.name || el.description} - ${el?.start && el?.start?.toDate ? moment(el?.start?.toDate()).format("DD-MM-YYYY HH:mm") : "-"} a ${el?.end && el?.end?.toDate ? moment(el?.end?.toDate()).format("DD-MM-YYYY HH:mm") : "-"}`}
                </StyledText>
            </View>

            <View style={{ backgroundColor: "#f2f2f2", padding: 10, borderRadius: 7, marginTop: 15 }}>
                {!data ?
                    <StyledText style={{ fontSize: 16, textAlign: "center", opacity: .3 }}>No hay intentos realizados</StyledText>
                    :
                    data?.length === 0 ?
                        <StyledText style={{ fontSize: 16, textAlign: "center", opacity: .3 }}>No hay intentos realizados</StyledText>
                        :
                        data?.map((exam, i) => {
                            return (
                                <View key={i} style={{ marginBottom: 10 }}>
                                    <StyledText style={{ fontFamily: "DemiBold", fontSize: 20, marginBottom: 15 }}>Fecha del Intento: {moment(exam?.createdAt?.toDate()).format("DD-MM-YYYY HH:mm")}</StyledText>
                                    <View style={{ flex: 1, flexDirection: "row", alignItems: "flex-start" }}>
                                        {exam.status !== "COMPLETED" ?
                                            <View style={{ flex: 1, marginRight: 10, padding: 10, backgroundColor: "white", borderRadius: 5, alignItems: "center" }}>
                                                <StyledText style={{ fontSize: 16, textAlign: "cemter" }}>Examen pendiente de revisión por el profesor</StyledText>
                                            </View>
                                            :
                                            content?.showSimplifyGrade ?
                                                <View style={{ flex: 1, flexDirection: "row" }}>
                                                    {((content?.percentageToPass * 10) <= exam?.percentage || content?.percentageToPass <= exam?.grade) ?
                                                        <View style={{ backgroundColor: "white", padding: 5, paddingHorizontal: 10, borderRadius: 5, alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                                                            {/* <StyledText style={{ fontSize: 12 }}>Calificación</StyledText> */}
                                                            <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>APTO</StyledText>
                                                        </View>
                                                        :
                                                        <View style={{ backgroundColor: "white", padding: 5, paddingHorizontal: 10, borderRadius: 5, alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                                                            {/* <StyledText style={{ fontSize: 12 }}>Calificación</StyledText> */}
                                                            <StyledText style={{ fontFamily: "Title", fontSize: 30, color: "red" }}>NO APTO</StyledText>
                                                        </View>

                                                    }
                                                </View>
                                                :
                                                <View style={{ flex: 1, flexDirection: "row" }}>
                                                    <View style={{ backgroundColor: "white", padding: 5, paddingHorizontal: 10, borderRadius: 5, alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                                                        <StyledText style={{ fontSize: 12 }}>Nota</StyledText>
                                                        <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>{clamp(Math.fround((exam?.grade || 0) * 100) / 100, 0, 10)}</StyledText>
                                                    </View>
                                                    <View style={{ backgroundColor: "white", padding: 5, paddingHorizontal: 10, borderRadius: 5, alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                                                        <StyledText style={{ fontSize: 12 }}>Aciertos</StyledText>
                                                        <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>{exam?.correct}</StyledText>
                                                    </View>
                                                    <View style={{ backgroundColor: "white", padding: 5, paddingHorizontal: 10, borderRadius: 5, alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                                                        <StyledText style={{ fontSize: 12 }}>Fallos</StyledText>
                                                        <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>{exam?.wrong}</StyledText>
                                                    </View>
                                                    <View style={{ backgroundColor: "white", padding: 5, paddingHorizontal: 10, borderRadius: 5, alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                                                        <StyledText style={{ fontSize: 12 }}>Sin Cont.</StyledText>
                                                        <StyledText style={{ fontFamily: "Title", fontSize: 30 }}>{(exam?.total || 0) - (exam?.correct || 0) - (exam?.wrong || 0)}</StyledText>
                                                    </View>
                                                </View>
                                        }
                                        {content?.showAnswersAfterTry ? content?.deadlines?.sort((a, b) => b.end.toDate() - a.end.toDate())[0]?.end.toDate() < new Date() ? (
                                            <Button label={"Ver"} color="#0b1831" round="5px" height="35px" style={{ marginRight: 10 }} onPress={() => openExamCallback({ ...exam, preview: true })} />
                                        ) : null : <Button label={"Ver"} color="#0b1831" round="5px" height="35px" style={{ marginRight: 10 }} onPress={() => openExamCallback({ ...exam, preview: true })} />}
                                    </View>
                                </View>
                            )
                        })
                }
            </View>

            {data?.every(el => el.status === "COMPLETED") && !data?.some(el => el.grade >= content?.percentageToPass) && canUploadNow && attempts > data?.length &&
                <View style={{ marginTop: 15, flexDirection: "row", justifyContent: "flex-end" }}>
                    <Button onPress={() => {
                        const p = confirm("¿Estás seguro de querer realizar el intento? Una vez comiences se descontará de tus intentos restantes")
                        if (p) {
                            startExam(el.id)
                        }
                    }} disabled={loading} label="Comenzar Examen" height="35px" round="7px" />
                </View>
            }
        </View>
    )
}

const fixDate = (date) => {
    if (date instanceof Date) {
        return date
    } else if (typeof date === "string") {
        return moment(date).toDate()
    } else if (date instanceof Timestamp) {
        return date.toDate()
    } else if (typeof date === "number") {
        return moment(date).toDate()
    } else {
        return null
    }
}

const DeadlineUpload = ({ el, enabled, contentId, deadlineId, tenantId, advanceRef, canUploadNow, data, teachers, content, course }) => {


    const db = getFirestore()
    const storage = getStorage()
    const { userData } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [commentsCollapsed, setCommentsCollapsed] = useState(!canUploadNow)
    const historyRef = useRef(null)

    const coordinators = get(course, "teachers", [])

    const deadlineRef = doc(db, `${advanceRef?.path}/contents/${deadlineId}`)

    const mutation = useFirestoreDocumentMutation(deadlineRef, { merge: true })

    const hasTeacherFiles = (data?.teacherFiles && data?.teacherFiles?.length > 0)

    const updateTask = async () => {
        setLoading(true)

        DocumentPicker.getDocumentAsync()
            .then(async res => {

                if (res.type === "success") {
                    const init = res.uri.split(";")[0]

                    if (res.size / 1024 / 1024 > 500) {
                        alert("El archivo no puede ser mayor a 500MB")
                        setLoading(false)
                        return
                    }

                    try {
                        setLoading(true)
                        const file = ref(storage, `courses/${tenantId}/${advanceRef.parent.id}/${userData.id}/${new Date().getTime()}-${res.name}`);

                        if (init) {
                            await uploadString(file, res.uri.replace(`${init};base64,`, ""), "base64", {
                                contentType: init.replace("data:", ""),
                            })
                        } else {
                            await uploadBytes(file, res.output.item(0))
                        }


                        const p = file.fullPath

                        if (data) {

                            mutation.mutate({
                                updatedAt: serverTimestamp(),
                                teachers,
                                coordinators,
                                newUpdate: true,
                                tenantId: tenantId,
                                contentType: "TASK",
                                userName: userData.name + " " + userData.surname,
                                userImage: userData.image,
                                userId: userData.id,
                                courseName: course.name,
                                courseTag: course.tag,
                                name: content.title,
                                courseId: course.id,
                                status: "PENDINGREVIEW",
                                lastUpdate: {
                                    createdAt: serverTimestamp(),
                                    by: userData?.id || "",
                                    name: userData?.name || "",
                                    surname: userData?.surname || "",
                                    image: userData?.image || "",
                                    action: "upload",
                                    role: "Student",
                                    type: "FILE",
                                    file: {
                                        uri: p,
                                        createdAt: new Date().valueOf()
                                    }
                                },
                                files: arrayUnion({
                                    uri: p,
                                    createdAt: new Date().valueOf()
                                })
                            })

                        } else {
                            mutation.mutate({
                                contentId,
                                tenantId: tenantId,
                                createdAt: serverTimestamp(),
                                updatedAt: serverTimestamp(),
                                teachers,
                                coordinators,
                                newUpdate: true,
                                contentType: "TASK",
                                userName: userData.name + " " + userData.surname,
                                userImage: userData.image,
                                userId: userData.id,
                                courseName: course.name,
                                courseTag: course.tag,
                                name: content.title,
                                courseId: course.id,
                                lastUpdate: {
                                    createdAt: serverTimestamp(),
                                    by: userData?.id || "",
                                    name: userData?.name || "",
                                    surname: userData?.surname || "",
                                    image: userData?.image || "",
                                    action: "upload",
                                    role: "Student",
                                    type: "FILE",
                                    file: {
                                        uri: p,
                                        createdAt: new Date().valueOf()
                                    }
                                },
                                files: [{
                                    uri: p,
                                    createdAt: new Date().valueOf()
                                }]
                            })
                        }

                        setLoading(false)

                    } catch (err) {
                        alert("Ha ocurrido un error cargando el documento")
                        setLoading(false)
                        console.log(err)
                    }
                } else {
                    alert("No se ha seleccionado ningún archivo")
                    setLoading(false)
                }
                return res
            }).catch(err => {
                alert("Ocurrió un error cargando el docuento")
                console.log(err)
                setLoading(false)
                return null
            }).finally(() => setLoading(false))


    }

    const deleteFile = async (el) => {
        const p = confirm("¿Estás seguro de querer eliminar este archivo?")
        if (p) {
            setLoading(true)
            try {
                mutation.mutate({
                    lastUpdate: {
                        createdAt: serverTimestamp(),
                        by: userData?.id || "",
                        name: userData?.name || "",
                        surname: userData?.surname || "",
                        image: userData?.image || "",
                        action: "delete",
                        role: "Student",
                        type: "FILE",
                        file: el
                    },
                    updatedAt: serverTimestamp(),
                    teachers,
                    newUpdate: true,
                    tenantId: tenantId,
                    files: arrayRemove(el)
                })
                setLoading(false)
            } catch (err) {
                console.log(err)
                setLoading(false)
                alert("Ocurrió un error eliminando el archivo")
            }
        }
    }


    return (
        <>
            <DeadlineHistory ref={historyRef} advanceRef={deadlineRef} />
            <View style={{ marginBottom: 15 }}>

                {/* TITULO */}
                <TouchableOpacity onPress={() => setCommentsCollapsed(e => !e)} style={{ backgroundColor: "#f7f7f7", paddingVertical: 10, borderRadius: 5, paddingLeft: 15, opacity: 1, flexDirection: "row", alignItems: "center" }}>
                    <View style={{ flex: 1, alignItems: "flex-start", justifyContent: "center" }}>
                        <StyledText style={{ fontSize: 18, fontWeight: "bold" }}>
                            {el.name || el.description}
                        </StyledText>
                        <StyledText style={{ marginTop: 5, fontSize: 16 }}>
                            {`De ${el?.start && el?.start?.toDate ? moment(el?.start?.toDate()).format("DD-MM-YYYY HH:mm") : "-"} a ${el?.end && el?.end?.toDate ? moment(el?.end?.toDate()).format("DD-MM-YYYY HH:mm") : "-"}`}
                        </StyledText>
                        {data?.status && <View style={{ borderRadius: 5, marginTop: 5, backgroundColor: "#0b1831", paddingHorizontal: 15, paddingVertical: 7 }}>
                            <StyledText style={{ color: "white" }}>{getStatusName(data?.status)}</StyledText>
                        </View>}
                    </View>

                    {content?.deadlinesUploads ? <View style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }}>
                        <Ionicons name={commentsCollapsed ? "chevron-forward" : "chevron-down"} size={25} />
                    </View> : null}
                </TouchableOpacity>

                {content?.deadlinesUploads ? <View style={{ height: commentsCollapsed ? 0 : null, marginTop: commentsCollapsed ? 0 : 15, paddingLeft: 10, overflow: "hidden" }}>

                    <View style={{ marginTop: 10, alignItems: "center", flexDirection: "row" }}>
                        <View style={{ flex: 1, alignItems: "center", flexDirection: "row", justifyContent: "space-between" }}>
                            <View style={{ flex: 1, alignItems: "center", flexDirection: "row" }}>
                                <Ionicons name="cloud-upload-outline" size={24} color="#0b1831" style={{ marginRight: 5 }} />
                                <StyledText style={{ fontSize: 16, fontWeight: "bold" }}>Mis archivos <StyledText style={{fontSize:12}}>(500mb max)</StyledText></StyledText>
                            </View>
                            {data?.files ? <TouchableOpacity
                                onPress={() => historyRef.current.open()}
                                style={{ width: 40, height: 40, alignItems: "center", justifyContent: "center" }}>
                                <MaterialCommunityIcons name="history" size={24} color="#0b1831" style={{ marginRight: 5 }} />
                            </TouchableOpacity> : null}
                        </View>
                        {enabled && canUploadNow && data?.status !== "APPROVED" && data?.status !== "FAILED" && data?.status !== "NOTSUBMITTED" ?
                            <View style={{ flexDirection: "row", justifyContent: "flex-end", gap: 10 }}>
                                <Button disabled={loading} onPress={() => updateTask(el.id)} loading={loading} label="Cargar Contenido" height="35px" round="7px" />
                            </View>
                            : null}
                    </View>
                    {content?.boardTask ?
                        <View style={{ columnGap: 10, marginTop: 10, alignItems: "center", flexDirection: "row" }}>
                            <Button style={{ flex: 1 }} primary={false} color={"#0b1831"} label="Camelot Tareas" onPress={() => window.open("https://onformacion.rfef.es/alumno/exercises", "_blank")} height="35px" round={7} />
                            <Button style={{ flex: 1 }} primary={false} color={"#0b1831"} label="Camelot Video" onPress={() => window.open("https://onformacion.rfef.es/alumno/videoexercises", "_blank")} height="35px" round={7} />
                        </View>
                        : null
                    }
                    {/* ARCHIVOS SUBIDOS POR EL ALUMNO */}
                    {enabled && <View style={{ backgroundColor: "#f2f2f2", padding: 10, borderRadius: 7, marginTop: 10 }}>
                        {(!data?.files || data?.files?.length === 0) ?
                            <StyledText style={{ fontSize: 16, textAlign: "center", opacity: .3 }}>No hay archivos</StyledText>
                            :
                            data?.files?.map((file, i, arr) => {
                                const canDelete = (data?.status === "PENDINGREVIEW" && fixDate(data?.lastCorrection) < fixDate(file.createdAt) || !data?.lastCorrection)
                                return (
                                    <View key={i} style={{ flexDirection: "row", marginBottom: arr.length - 1 === i ? 0 : 10, alignItems: "center", justifyContent: "space-between" }}>
                                        <View style={{ flex: 1, paddingRight: 10 }}>
                                            <StyledText numberOfLines={1} style={{ fontSize: 16 }}>{file?.uri?.split("/").pop().split(".").slice(0, -1).join("")}</StyledText>
                                        </View>
                                        <Button icon="eye-outline" color="#f2f2f2" paddingHorizontal={15} round="5px" height="35px" width="35px" style={{ marginRight: canUploadNow && data?.status === "PENDINGREVIEW" ? 10 : 0 }} onPress={() => {
                                            var windowReference = window.open();
                                            const fileRef = ref(storage, file.uri)
                                            getDownloadURL(fileRef).then(res => {
                                                windowReference.location = res
                                            }).catch(err => alert("Error obteniendo el archivo"))
                                        }} />
                                        {canUploadNow && canDelete && (data?.status === "PENDINGREVIEW" || !data?.status) && <Button style={{ marginLeft: 10 }} icon="trash" color="gray" round="5px" width="35px" height="35px" onPress={() => {
                                            deleteFile(file)
                                        }} />}
                                    </View>
                                )
                            })
                        }
                    </View>}

                    {/* BOTON DE CARGAR CONTENIDO */}
                    {data?.comments &&
                        <View>
                            <View style={{ marginTop: 15, flexDirection: "row", alignItems: "center" }}>
                                <View style={{ flex: 1, alignItems: "center", flexDirection: "row" }}>
                                    <Ionicons name="information-circle-outline" size={24} color="#0b1831" style={{ marginRight: 5 }} />
                                    <StyledText style={{ fontSize: 16, fontWeight: "bold" }}>Comentarios del Profesor</StyledText>
                                </View>

                            </View>

                            <View style={{ paddingHorizontal: 10, marginTop: 10, borderRadius: 5, backgroundColor: "white", backgroundColor: "#f2f2f2", borderRadius: 7 }}>
                                <div dangerouslySetInnerHTML={{ __html: data?.comments || "<p></p>" }} style={{ fontFamily: "Regular" }} />
                            </View>


                        </View>
                    }

                    {hasTeacherFiles &&
                        <View style={{ marginTop: 15 }}>
                            <View style={{ flex: 1, alignItems: "center", flexDirection: "row" }}>
                                <Ionicons name="documents-outline" size={24} color="#0b1831" style={{ marginRight: 5 }} />
                                <StyledText style={{ fontWeight: "bold", fontSize: 16 }}>Archivos del Profesor</StyledText>
                            </View>
                            <View style={{ backgroundColor: "#f2f2f2", padding: 10, borderRadius: 7, marginTop: 15 }}>
                                {data?.teacherFiles?.map((file, i, arr) => {
                                    return (
                                        <View key={i} style={{ flexDirection: "row", marginBottom: arr.length - 1 === i ? 0 : 10, alignItems: "center", justifyContent: "space-between" }}>
                                            <View style={{ flex: 1, paddingRight: 10 }}>
                                                <StyledText numberOfLines={1} style={{ fontSize: 16 }}>{file?.uri?.split("/").pop().split(".").slice(0, -1).join("")}</StyledText>
                                            </View>
                                            <Button icon="eye-outline" color="#f2f2f2" paddingHorizontal={15} round="5px" height="35px" width="35px" style={{ marginRight: canUploadNow && data?.status === "PENDINGREVIEW" ? 10 : 0 }} onPress={() => {
                                                var windowReference = window.open();
                                                const fileRef = ref(storage, file.uri)
                                                getDownloadURL(fileRef).then(res => {
                                                    windowReference.location = res
                                                }).catch(err => alert("Error obteniendo el archivo"))
                                            }} />
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    }

                </View> : null}



            </View >
        </>
    )
}

const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + ":" : "";
    var mDisplay = m > 0 ? (m < 10 ? "0" + m + ":" : m + ":") : "00:";
    var sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
    return `${h > 0 ? hDisplay : ""}${mDisplay}${sDisplay}`
}

const Timer = ({ duration, endExamCallback }) => {
    const [time, setTime] = useState(duration)
    const [timer, setTimer] = useState(null)

    useEffect(() => {
        if (time > 0) {
            setTimer(setTimeout(() => {
                setTime(time - 1)
            }, 1000))
        } else {
            clearTimeout(timer)
            endExamCallback()
        }
    }, [time])

    return (
        // <CircularIndicator percentage={time / duration * 100} strokeWidth={6} r={25} insideComponent={<StyledText style={{ fontFamily: "Title", fontSize: 24, color: "white" }}>{secondsToHms(time)}</StyledText>} />
        <>
            <View >
                <StyledText style={{ fontWeight: "bold", fontFamily: "Title", fontSize: 40, color: "white" }}>{secondsToHms(time)}</StyledText>
            </View>
            <View style={{ position: "fixed", left: 0, right: 0, top: 10, height: 10, backgroundColor: "gainsboro" }}>
                <View style={{ width: `${time * 100 / duration}%`, height: "100%", backgroundColor: "#fcbb2c" }} />
            </View>
        </>
    )
}

const QuestionComponent = ({ preview, control, random, watch, disabled, question, index }) => {

    const ab = watch(`questions[${index}].response`)

    function isVideoFile(fileName) {
        const videoExtensions = ['.mp4', '.mov', '.avi', '.mkv', '.flv', '.wmv', '.webm', '.m4v'];

        const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

        return videoExtensions.includes(fileExtension);
    }

    return (
        <View style={{ marginTop: 15 }}>
            <StyledText style={{ fontFamily: "TitleWide", fontSize: 18 }}>{index + 1} - {question.question}</StyledText>

            {question.media &&
                <View style={{ flexDirection: "row", marginTop: 10, flexWrap: "wrap" }}>
                    {question.media?.map((media, i) => {
                        return (
                            <View key={i}>
                                {
                                    (isVideoFile(media)) ? (
                                        <SecureVideo uri={media} style={{ width: 250, height: 150, resizeMode: "cover" }} />
                                    ) : (
                                        media?.includes(".pdf") ?
                                            <SecurePDF uri={media} style={{ minHeight: 400, width: "100%", backgroundColor: "gainsboro", borderRadius: 7 }} />
                                            :
                                            <SecureImage uri={media} style={{ width: 250, height: 150, resizeMode: "cover" }} />
                                    )}
                            </View>
                        )
                    })}
                </View>
            }

            <View style={{ marginTop: 10 }}>
                <Controller
                    control={control}
                    name={`questions[${index}].response`}
                    render={({ field }) => {
                        return question.openAnswerValue ?
                            <View>
                                <TextInput multiline={true} placeholder="Respuesta..." value={field.value} onChangeText={field.onChange} style={{ textAlignVertical: "top", height: 150, padding: 10, borderWidth: 1, borderColor: "gainsboro" }} numberOfLines={4} disabled={disabled} />

                                {question?.globalFeedbackText && preview &&
                                    <View style={{ padding: 5, borderRadius: 2, marginTop: 15, paddingVertical: 10, paddingHorizontal: 10, backgroundColor: "#f2f2f2", flexDirection: "row", alignItems: "center" }}>
                                        <Ionicons name="information-circle-outline" size={20} color="black" style={{ marginRight: 5 }} />
                                        <StyledText style={{ fontFamily: "Regular", color: "black" }}>{question?.globalFeedbackText}</StyledText>
                                    </View>
                                }
                                {question?.observations &&
                                    <div dangerouslySetInnerHTML={{ __html: question.observations }} style={{ marginTop: 10, fontSize: 16, padding: "0px 15px", fontFamily: "Regular", backgroundColor: "#f2f2f2" }} />
                                }
                            </View>
                            :
                            <>
                                {question.answers?.map((answer, i) => {
                                    // console.log(question?.multipleAnswerValue)
                                    // const highlightColor = getAnswerColor(i, field.value, question.correctAnswer, preview)
                                    // !disabled ? i === field.value ? "#0b1831" : null : answer.correctAnswer ? "green" : i === field.value ? "red" : null
                                    const selected = question?.multipleAnswerValue ? field?.value?.includes(i) : field.value === i
                                    if (preview) {
                                        return (
                                            <AnswerComponentPreview key={i} text={answer.text} answer={selected} correct={answer.correctAnswer} selected={selected} feedback={answer?.feedback} />
                                        )
                                    } else {
                                        return (
                                            <AnswerComponent
                                                onPress={() => {
                                                    if (question.multipleAnswerValue) {
                                                        field?.value?.includes(i) ? field.onChange(field.value.filter(el => el !== i)) : field.onChange([...(field.value || []), i])
                                                    } else {
                                                        selected ? field.onChange(null) : field.onChange(parseInt(i))
                                                    }
                                                }}
                                                key={i}
                                                text={answer.text}
                                                disabled={disabled}
                                                selected={selected}
                                            />
                                        )
                                    }
                                })}
                                {question?.globalFeedbackText && preview &&
                                    <View style={{ padding: 5, borderRadius: 2, marginTop: 15, paddingVertical: 10, paddingHorizontal: 10, backgroundColor: "#f2f2f2", flexDirection: "row", alignItems: "center" }}>
                                        <Ionicons name="information-circle-outline" size={20} color="black" style={{ marginRight: 5 }} />
                                        <StyledText style={{ fontFamily: "Regular", color: "black" }}>{question?.globalFeedbackText}</StyledText>
                                    </View>
                                }
                            </>
                    }}
                />
            </View>

        </View>
    )
}

const AnswerComponent = ({ text, onPress, disabled, selected }) => (
    <TouchableOpacity onPress={onPress} style={{ flexDirection: "row", alignItems: "center", marginBottom: 10 }} disabled={disabled} >
        <View style={{ borderWidth: 2, borderColor: selected ? "#041e3b" : null, borderRadius: 5, alignItems: "center", justifyContent: "center", height: 25, width: 25, marginRight: 10 }}>
            {selected ? <Ionicons name="checkmark" size={20} color={"#041e3b"} /> : null}
        </View>

        <View style={{ flex: 1 }}>
            <StyledText style={{ fontFamily: "DemiBold", color: selected ? "#041e3b" : null }}>{text}</StyledText>
        </View>

    </TouchableOpacity>
)

const AnswerComponentPreview = ({ text, answer, correct, selected, feedback }) => (
    <View style={{ flexDirection: "row", alignItems: feedback ? "flex-start" : "center", marginBottom: 10 }}>
        <View style={{ borderWidth: 2, borderColor: correct ? "green" : answer && !correct ? "red" : "black", borderRadius: 5, alignItems: "center", justifyContent: "center", height: 25, width: 25, marginRight: 10 }}>
            {selected && (answer || correct) ?
                <Ionicons name={correct ? "checkmark" : answer && !correct ? "close" : "black"} size={20} color={correct ? "green" : answer && !correct ? "red" : "black"} />
                :
                null
            }
        </View>
        <View style={{ flex: 1, alignItems: "flex-start" }}>
            <StyledText style={{ fontFamily: "DemiBold", color: correct ? "green" : answer && !correct ? "red" : "black" }}>{text}</StyledText>
            {feedback && selected &&
                <View style={{ padding: 5, borderRadius: 2, backgroundColor: "#f2f2f2", paddingVertical: 10, paddingHorizontal: 10, flexDirection: "row", alignItems: "center" }}>
                    <Ionicons name="information-circle-outline" size={20} color="black" style={{ marginRight: 5 }} />
                    <StyledText style={{ fontFamily: "Regular", color: "black" }}>{feedback}</StyledText>
                </View>
            }
        </View>

    </View>
)

const getStatusName = (status) => {
    switch (status) {
        case "PENDINGREVIEW":
            return "Pendiente de revisión"
        case "CHANGESREQUIRED":
            return "Necesita cambios"
        case "FAILED":
            return "No Apto"
        case "APPROVED":
            return "Apto"
        case "NOTSUBMITTED":
            return "No entregado"
        default:
            return "Pendiente de revisión"
    }
}

const canUpload = (start, end, approved) => {
    if (approved) {
        return false
    } else if (start && end) {
        if (start?.toDate && end?.toDate) {
            const now = moment()
            const startMoment = moment(start?.toDate())
            const endMoment = moment(end?.toDate())
            return now.isBetween(startMoment, endMoment)
        }
    }
    return false
}

const ExamComponent = ({ course, content, advanceRef, advance, preview, setExamModal, tenantId, teachers }) => {

    const db = getFirestore()
    const params = useRoute().params as any
    const { userData } = useContext(AuthContext)
    const [endModal, setEndModal] = useState(false)
    const mutation = useFirestoreDocumentMutation(advanceRef, { merge: true })
    const mutationAdvance = useFirestoreDocumentMutation(advanceRef.parent.parent, { merge: true })
    const deletion = useFirestoreDocumentDeletion(advanceRef)

    const path = params?.path?.split("-")
    const pathString = path.reduce((acc, curr, i) => acc + (i === 0 ? `contentDetails[${curr}]` : `.sections[${curr}]`), "")
    const section = get(course, pathString, {})

    const {
        handleSubmit,
        watch,
        control
    } = useForm({
        defaultValues: {
            questions: preview ? advance?.questions || [] : (content?.randomQuestions ? shuffle(content?.questions) : content?.questions)?.slice(0, content?.numberOfQuestions || -1).map(el => ({ ...el, answers: content?.randomAnswers ? shuffle(el.answers) : el.answers })),
        }
    })


    const endExam = (values, force) => {
        if (preview) return setExamModal(false)
        force && alert("Se ha agotado el tiempo del examen, las preguntas que hubieses marcado se guardarán automáticamente")

        let correct = 0
        let grade = 0
        let percentage = 0
        let total = parseInt(content.numberOfQuestions)
        let wrong = 0

        values.questions.forEach((question, i) => {
            if (question.response !== undefined) {
                const correctIndex = question?.answers?.findIndex(el => el.correctAnswer)
                if (question.response === correctIndex) {
                    correct++
                } else {
                    wrong++
                }
            }
        })

        const hasOpenAnswers = values.questions.some(el => el.openAnswerValue)
        const correctValue = correct * parseFloat(content.correctValue || 0)
        const wrongValue = wrong * parseFloat(content.wrongValue || 0)
        const noAnsweredValue = (total - correct - wrong) * parseFloat(content.noAnswerValue || 0)
        grade = correctValue + wrongValue + noAnsweredValue
        percentage = Math.round(grade / (total * parseFloat(content.correctValue || 0)) * 100 * 100) / 100



        const results = {
            correct: correct || 0,
            wrong: wrong || 0,
            total: total || 0,
            grade: grade || 0,
            percentage: percentage || 0,
            endTime: serverTimestamp(),
            updatedAt: serverTimestamp(),
            questions: values.questions.map(el => ({ ...el, response: el?.response === undefined ? null : el.response })),
            status: hasOpenAnswers ? "PENDINGREVIEW" : "COMPLETED",
            tenantId: tenantId,
            teachers: teachers,
            newUpdate: hasOpenAnswers,
            contentType: "EXAM"
        }

        try {
            mutation.mutate(results)

            autoCalification({
                sectionContents: section.contents,
                colRef: advanceRef.parent,
                masterAdvance: advanceRef.parent.parent,
                section,
                userId: userData.id,
                mutationAdvance: mutationAdvance
            })

            setExamModal(false)
        } catch (err) {
            console.log(err)
            deletion.mutate()
            alert("Ha ocurrido un error al enviar el examen, por favor intenta de nuevo, se descontará el intento de tu cuenta")

        }

    }

    const questions = watch("questions")

    return (
        <>
            <Modal>
                <View style={{ width: "100vw", height: "100vh", backgroundColor: "white" }}>
                    <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center", backgroundColor: "#E4002B" }}>
                        <View style={{ flex: 1, paddingLeft: 10, paddingVertical: 10, paddingRight: 10 }}>
                            <StyledText style={{ fontFamily: "Title", fontSize: 34, color: "white" }}>{content.title}</StyledText>
                            <StyledText style={{ fontFamily: "DemiBold", fontSize: 16, color: "white" }}>{`Preguntas contestadas ${(questions)?.filter(el => el.response !== undefined && el.response !== null)?.length || 0} de ${content?.numberOfQuestions}`}</StyledText>
                        </View>
                        {!preview && content.contentLength ? <Timer duration={content?.contentLength * 60} endExamCallback={handleSubmit((v) => endExam(v, true))} /> : null}
                        {!preview && <View style={{ marginHorizontal: 10 }}>
                            <Button color={"white"} label="Finalizar" height="35px" round="7px" onPress={() => setEndModal(true)} />
                        </View>}
                        {preview && <TouchableOpacity style={{ marginHorizontal: 15, height: "100%", alignItems: "center", justifyContent: 'center' }} onPress={() => setExamModal(false)}>
                            <Ionicons name="close" size={35} color="white" />
                        </TouchableOpacity>}
                    </View>

                    <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1, padding: 15 }}>
                        {questions.map((el, i) => <QuestionComponent
                            watch={watch}
                            preview={preview}
                            control={control}
                            random={el?.randomAnswers ? preview ? false : true : false}
                            disabled={preview}
                            question={el}
                            index={i}
                            key={i}
                        />
                        )}
                    </ScrollView>

                </View>
            </Modal >
            {endModal ? <Modal>
                <View style={{ backgroundColor: "white", maxWidth: 350 }}>
                    <View>
                        <StyledText style={{ fontSize: 16, color: "black", textAlign: "center" }}>¿Estás seguro que quieres finalizar el examen? Asegurate de haber completado todas las preguntas antes de enviarlo</StyledText>
                        <StyledText style={{ marginTop: 15, textAlign: "center", fontFamily: "DemiBold", fontSize: 20 }}>{`Has contestado ${(questions)?.filter(el => el.response !== undefined && el.response !== null)?.length || 0} de ${content?.numberOfQuestions} preguntas`}</StyledText>
                    </View>
                    <View style={{ marginTop: 20, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <Button color={"#f2f2f2"} label="Cancelar" height="35px" round="7px" onPress={() => setEndModal(false)} />
                        <Button color={"#E4002B"} label="Finalizar" height="35px" round="7px" onPress={handleSubmit((v) => endExam(v, false))} />

                    </View>
                </View>
            </Modal > : null}
        </>
    )
}

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 1)",
        shadowOffset: { width: 0, height: 7 },
        shadowOpacity: 0.1,
        shadowRadius: 10,
        elevation: 10,
    },
});