import { FontAwesome, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useFirestoreDocument, useFirestoreDocumentData } from "@react-query-firebase/firestore";
import { LinearGradient } from "expo-linear-gradient";
import { getApp } from "firebase/app";
import { Timestamp, doc, getDoc, getFirestore, serverTimestamp, setDoc } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import _, { clamp, get, omit } from "lodash";
import moment from "moment";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { CropperRef } from "react-advanced-cropper";
import { useForm } from "react-hook-form";
import { ActivityIndicator, Platform, ScrollView, TouchableOpacity, View, useWindowDimensions } from "react-native";
import { useQuery } from "react-query";
import { useMediaQuery } from "react-responsive";
import * as yup from "yup";
import Stepper from "../../components/Stepper";
import Button from "../../components/common/Button";
import Footer from "../../components/common/Footer";
import Modal from "../../components/common/Modal";
import SecureImage from "../../components/common/SecureImage";
import StyledText from "../../components/common/StyledText";
import Subform from "../../components/common/Subform";
import CustomFormCV from "../../components/customForms/CustomFormCV";
import CustomFormDeclaracionJurada from "../../components/customForms/CustomFormDeclaracionJurada";
import CustomFormDeclaracionMedica from "../../components/customForms/CustomFormDeclaracionMedica";
import ImageField from "../../components/formComponents/ImageField";
import MultiField from "../../components/formComponents/MultiField";
import SelectField from "../../components/formComponents/SelectField";
import TextField from "../../components/formComponents/TextField";
import CourseCardSquare from "../../components/publicComponents/CourseCardSquare";
import AuthContext from "../../context/AuthContext";
import countries from "../../utils/countries.json";
import fixDate from "../../utils/fixDate";
import getValidationType from "../../utils/getValidationType";
import makeCecabankPayment from "../../utils/makeCecabankPayment";
import provinces from "../../utils/provinces.json";
import sanitizeObject from "../../utils/sanitizeObject";
import swapIcon from "../../utils/swapIcon";
import useYupValidationResolver from "../../utils/useYupValidationResolver";


const PreviewCourseScreen = ({ route }) => {
	const screenWidth = useWindowDimensions().width;
	const isPhone = screenWidth < 1000
	const { userData, impersonatedUser } = useContext(AuthContext)
	const scrollRef = useRef();

	const { tenantId, id, order } = route.params
	const [tab, setTab] = useState(0);
	const [weekSelected, setWeekSelected] = useState(0);
	const [sectionOpen, setSectionOpen] = useState({ s: null, i: null });

	const [register, setRegister] = useState(false);

	const navigation = useNavigation()

	const db = getFirestore()
	const courseRef = doc(db, `publicCourses/${id}`)
	const { data, isError, isLoading } = useFirestoreDocumentData([`courses`, id], courseRef, {}, {
		onSuccess: data => navigation.setOptions({ title: data?.name })
	});

	useEffect(() => {
		!isLoading && data && document.getElementById('mainBanner').scrollIntoView();
	}, [id])

	const app = getApp()
	const functions = getFunctions(app, "europe-west1")

	process.env.NODE_ENV === "development" && connectFunctionsEmulator(functions, "localhost", 5001);


	const { data: dataCourses } = useQuery({
		queryKey: ['courses'],
		queryFn: () => fetch('https://onformacion.comerciogo.workers.dev/courses').then((res) => res.json()),
		select: querySnapshot => querySnapshot?.filter(x => x?.tenantId === "OF4VYpRfgUJEH5rG2syJ")
			.map(doc => {
				const initDate = fixDate(doc?.initDate)
				const endDate = fixDate(doc?.endDate)
				return {
					...doc,
					initDate,
					endDate,
				}
			}) || []
	})

	const regRef = doc(db, `tenants/${tenantId}/courses/${id}/courseavs/${userData?.id}`)
	const { data: courseExists } = useFirestoreDocument(
		[`courseAvs`, tenantId, id, userData?.id], regRef,
		{
			subscribe: true,
		},
		{
			enabled: !!id && !!userData?.id,
			select: d => {
				return !d.exists() ? null : d.data()
			},
			onError: e => console.log(e)
		});

	useEffect(() => {
		if (order && data) setRegister(true);
	}, [order, data]);

	useEffect(() => {
		return () => {
			if (navigation) {
				navigation.setParams({ ...omit(route.params, 'order') })
			}
		};
	}, [navigation]);


	const courseMaxStudents = data?.maxAlumns || 0;

	const isEnrollmentFull = (parseInt(courseMaxStudents) === 0 || typeof courseMaxStudents === "undefined") ? false : data?.alumns >= parseInt(courseMaxStudents);

	return (
		<>
			{register ? <RegisterModal paymentSuccess={order} setOpen={setRegister} course={data} courseId={id} preinscriptionData={get(data, "preinscriptionData", [])} functions={functions} /> : null}
			<ScrollView
				style={{ flex: 1, backgroundColor: "white" }}
				contentContainerStyle={{ flexGrow: 1 }}
				ref={scrollRef}
				scrollEventThrottle={60}
			>

				{(!isLoading && !data) ?
					<View style={{ flex: 1, minHeight: 300, marginTop: 50, paddingHorizontal: 20, width: "100%", backgroundColor: "#f3f3f3", borderRadius: 7, maxWidth: 800, alignSelf: "center", justifyContent: "center", alignItems: "center" }}>
						<StyledText style={{ fontSize: 20, textAlign: "center" }}>El enlace que has seguido no se encuentra disponible</StyledText>
					</View>
					:
					<View style={{ flexGrow: 1 }}>
						<View nativeID="mainBanner" style={[{ width: "100%", paddingVertical: 50, alignItems: "center", justifyContent: "center" }]}>
							<SecureImage
								style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 1, }}
								uri={data?.image || "https://source.unsplash.com/1600x900/?soccer"}
							/>
							<View style={{ flex: 1, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 2, backgroundColor: "rgba(0,0,0,.6)" }} />
							<View style={{ width: "100%", maxWidth: 1200, flexDirection: isPhone ? "column" : "row", alignItems: "center", marginVertical: 25, paddingHorizontal: 15, zIndex: 4 }}>
								<View style={{ flex: 1 }}>
									<StyledText numberOfLines={3} style={{ textAlign: isPhone ? "center" : "left", fontSize: isPhone ? 45 : 60, color: "white", fontFamily: "Title" }}>
										{data?.name || ""}
									</StyledText>
									<StyledText numberOfLines={1} style={{ textAlign: isPhone ? "center" : "left", fontSize: 20, color: "white", fontFamily: "TitleWide", opacity: .8, marginBottom: 20, marginTop: -10 }}>
										{data?.tag || ""}
									</StyledText>
									<View style={{ flexDirection: isPhone ? "column" : "row", flexWrap: "wrap", alignItems: "center", marginVertical: isPhone ? 20 : 0 }}>
										<StyledText style={{ fontSize: 18, color: "white", fontWeight: "bold" }}>{data?.courseType}</StyledText>
										{!isPhone ? <StyledText style={{ fontSize: 18, color: "white", fontWeight: "bold" }}>{` - `}</StyledText> : null}
										<StyledText style={{ fontSize: 18, color: "#F76254", fontWeight: "bold" }}> {data?.courseCategory}</StyledText>
									</View>
									{(data?.tenantName) ?
										<View style={{ justifyContent: "center", marginBottom: 20, marginTop: 10 }}>
											{data?.tenantName && <StyledText style={{ fontFamily: "TitleWide", textAlign: isPhone ? "center" : "left", fontSize: 16, color: "white" }}>{data.tenantName}</StyledText>}
										</View>
										: null
									}
								</View>
								<CourseRegisterButton isEnrollmentFull={isEnrollmentFull} preInitDate={data?.preInitDate} preEndDate={data?.preEndDate} id={id} tenantId={tenantId} courseExists={courseExists} setRegister={setRegister} />
							</View>
						</View>
						<View style={{ maxWidth: 1200, width: "100%", zIndex: 3, alignSelf: "center" }}>
							{data?.contentDetails.length > 0 ? <View style={{ height: 50, width: "100%", flexDirection: "row", backgroundColor: "#F9F9F9" }}>
								<TouchableOpacity
									onPress={() => setTab(0)}
									style={{ flex: 1, alignItems: "center", justifyContent: "center", borderBottomWidth: tab === 0 ? 3 : 0, borderBottomColor: "#F76254" }}
								>
									<StyledText style={{ fontSize: 18, fontWeight: tab === 0 ? "bold" : null }}>Información</StyledText>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => setTab(1)}
									style={{ flex: 1, alignItems: "center", justifyContent: "center", borderBottomWidth: tab === 1 ? 3 : 0, borderBottomColor: "#F76254" }}
								>
									<StyledText style={{ fontSize: 18, fontWeight: tab === 1 ? "bold" : null }}>Plan Formativo</StyledText>
								</TouchableOpacity>
							</View> : null}

							{tab === 0 ? (
								<View style={{ flexDirection: isPhone ? "column-reverse" : "row", paddingVertical: 50, paddingHorizontal: 15 }}>
									<View style={{ flex: 2 }}>
										<StyledText style={{ fontSize: 34, fontWeight: "bold", marginBottom: 20 }}>Información</StyledText>
										<div dangerouslySetInnerHTML={{ __html: data?.description || "<p></p>" }} style={{ fontFamily: "Regular", fontSize: 18, color: "#303030" }} />
									</View>
									<View style={{ flex: isPhone ? null : 1, marginLeft: isPhone ? 0 : 20 }}>
										<View style={{ width: "100%", borderRadius: 15, padding: 15, marginHorizontal: isPhone ? 0 : 5, marginBottom: 25, backgroundColor: "#F9F9F9" }}>
											<StyledText style={{ fontSize: 24, fontWeight: "bold" }}>Detalles</StyledText>

											{data ? <View style={{ backgroundColor: "white", borderRadius: 7, marginVertical: 15, paddingVertical: 10 }} >
												{(data?.programFee || 0) > 0 ?
													<View style={{ paddingVertical: 10, paddingHorizontal: 15, justifyContent: "center" }}>
														<StyledText style={{ fontSize: 20, marginBottom: 5, fontWeight: "bold", opacity: .2 }}>Coste del Programa</StyledText>
														<StyledText style={{ marginLeft: 10, fontFamily: "Title", fontSize: 24 }}> {(data?.programFee || 0)}€</StyledText>
													</View> : null}
												{(data?.preInitDate && data?.preEndDate) ?
													<View style={{ paddingVertical: 10, paddingHorizontal: 15, justifyContent: "center" }}>
														<StyledText style={{ fontSize: 20, marginBottom: 5, fontWeight: "bold", opacity: .2 }}>Preinscripción</StyledText>
														<StyledText style={{ marginLeft: 10, fontFamily: "Title", fontSize: 24 }}>Inicio: {moment(data?.preInitDate?.toDate()).format("DD MMM YYYY")}</StyledText>
														<StyledText style={{ marginLeft: 10, fontFamily: "Title", fontSize: 24 }}>Final: {moment(data?.preEndDate?.toDate()).format("DD MMM YYYY")}</StyledText>
													</View>
													: null
												}
												{(data?.initDate && data?.endDate) ?
													<View style={{ paddingVertical: 10, paddingHorizontal: 15, justifyContent: "center" }}>
														<StyledText style={{ fontSize: 20, marginBottom: 5, fontWeight: "bold", opacity: .2 }}>Duración</StyledText>
														<StyledText style={{ marginLeft: 10, fontFamily: "Title", fontSize: 24 }}>Inicio: {moment(data?.initDate?.toDate()).format("DD MMM YYYY")}</StyledText>
														<StyledText style={{ marginLeft: 10, fontFamily: "Title", fontSize: 24 }}>Final: {moment(data?.endDate?.toDate()).format("DD MMM YYYY")}</StyledText>
													</View>
													: null
												}
											</View> : null}

											<View style={{}}>
												{(data && data?.programBases) ?
													<Button onPress={() => {
														window.open(data.programBases)
													}} reverse icon="document" label="Información del Programa" round={5} />
													: null
												}
											</View>
										</View>
										<View style={{ width: "100%", borderRadius: 15, padding: 15, marginHorizontal: isPhone ? 0 : 5, marginBottom: 25, backgroundColor: "#F9F9F9" }}>

											{data ? <View style={{ backgroundColor: "white", borderRadius: 7, marginBottom: 15, paddingVertical: 10 }} >

												{(data?.tenantName) ?
													<View style={{ paddingVertical: 10, paddingHorizontal: 15, justifyContent: "center" }}>
														{data?.tenantName && <StyledText style={{ fontFamily: "TitleWide", fontSize: 16 }}>{data.tenantName}</StyledText>}
													</View>
													: null
												}
											</View> : null}
											{data ? <View style={{ backgroundColor: "white", borderRadius: 7, paddingVertical: 10 }} >
												{(data?.city || data?.community) ?
													<View style={{ paddingVertical: 10, paddingHorizontal: 15, justifyContent: "center" }}>
														{data?.community && <StyledText style={{ fontSize: 16 }}>Provincia: {data.community}</StyledText>}
														{data?.city && <StyledText style={{ fontSize: 16 }}>Localidad: {data.city}</StyledText>}
													</View>
													: null
												}
											</View> : null}
										</View>


									</View>
								</View>
							) : null}
							{tab === 1 ? (
								<View style={{ flexDirection: isPhone ? "column-reverse" : "row", paddingBottom: 50, paddingHorizontal: 15 }}>
									<View style={{ flex: 2 }}>
										{data?.contentDetails.map((el, i) => {
											const weekIndex = i + 1;
											return (
												<View nativeID={el.id} id={el.name} key={i} className="week" style={{ marginTop: 50 }}>
													<View>
														<StyledText style={{ fontSize: 34, fontWeight: "bold", marginBottom: 20 }}>{el.name}</StyledText>
														<StyledText style={{ fontSize: 16, marginBottom: 15 }}>
															{el.description}
														</StyledText>
													</View>

													<View style={{ marginTop: 25, marginHorizontal: 15 }}>
														{el?.sections?.map((section, i) => (
															<View key={i}>
																<TouchableOpacity
																	onPress={() => {
																		if (sectionOpen.s === weekIndex && sectionOpen.i === i) {
																			setSectionOpen({ s: null, i: null });
																		} else {
																			setSectionOpen({ s: weekIndex, i: i });
																		}
																	}}
																	style={{ height: 50, width: "100%" }}
																>
																	<LinearGradient
																		start={{ x: 0, y: 0 }}
																		end={{ x: 1, y: 1 }}
																		colors={i >= 5 ? ["gainsboro", "#f3f3f3"] : ["#006EB5", "#0D9CDA"]}
																		style={{ flex: 1, alignItems: "center", paddingHorizontal: 15, flexDirection: "row", borderRadius: 10, justifyContent: "space-between" }}
																	>
																		<StyledText style={{ fontSize: 18, color: "white", fontWeight: "bold" }}>{section.name}</StyledText>
																		<Ionicons name={sectionOpen.i === i && weekIndex === sectionOpen.s ? "chevron-up" : "chevron-down"} color="white" size={34} />
																	</LinearGradient>
																</TouchableOpacity>
																<View style={{ marginTop: 15, paddingHorizontal: 15, overflow: "hidden", height: sectionOpen.i === i && weekIndex === sectionOpen.s ? "auto" : 0 }}>
																	{section.contents.map((content, i) => (
																		<View key={i} style={{ flexDirection: "row", marginBottom: 10, alignItems: "center" }}>

																			<LinearGradient
																				start={{ x: 0, y: 0 }}
																				end={{ x: 1, y: 1 }}
																				colors={["#F55058", "#F77D4D"]}
																				style={{
																					alignItems: "center",
																					height: 35,
																					paddingHorizontal: 15,
																					flexDirection: "row",
																					borderRadius: 10,
																					marginRight: 10,
																					justifyContent: "space-between",
																				}}
																			>
																				<Ionicons name={swapIcon(content.contentType).icon} color="white" size={30} />
																				{/* <StyledText style={{ marginLeft: 5, fontSize: 18, color: "white", fontWeight: "bold" }}>{swapIcon(content.contentType)?.name}</StyledText> */}
																			</LinearGradient>
																			<StyledText style={{ fontSize: 18, color: "black", fontWeight: "bold" }} numberOfLines={2}>{content.title}</StyledText>
																		</View>
																	))}
																</View>
															</View>
														))}

													</View>
												</View>
											);
										})}
									</View>
									<View style={{ flex: isPhone ? null : 1, marginLeft: isPhone ? 0 : 20, paddingTop: 50 }}>
										<View style={{ width: "100%", borderRadius: 15, padding: 15, marginHorizontal: isPhone ? 0 : 5, backgroundColor: "#F9F9F9", position: "sticky", top: 50 }}>
											<StyledText style={{ fontSize: 24, fontWeight: "bold", marginBottom: 25 }}>Módulos del programa</StyledText>
											<View style={{}}>
												{data?.contentDetails.map((el, i) => {
													return (
														<TouchableOpacity
															key={i}
															href={`#${i}`}
															onPress={() => {
																Platform.OS === "web" && document.getElementById(el.id).scrollIntoView({
																	behavior: 'smooth'
																});

																// setScrolling(true);
																setWeekSelected(i);
																// scrollRef.current.scrollTo((i + 1) * 300);
																// setTimeout(() => {
																// 	setScrolling(false);
																// }, 1200);
															}}
															style={{
																zIndex: 2,
																margin: 5,
																paddingVertical: 15,
																paddingHorizontal: 15,
																borderRadius: 5,
																backgroundColor: weekSelected === i ? "white" : null,
																flexDirection: "row",
																alignItems: "center",
															}}
														>
															{weekSelected === i ? <Ionicons size={25} name="chevron-forward" color="#006eb5" /> : null}
															<StyledText style={{ fontSize: 18, fontWeight: "bold" }}>{el?.name}</StyledText>
														</TouchableOpacity>
													);
												})}
											</View>
										</View>
									</View>
								</View>
							) : null}
							{tab === 2 ? (
								<View style={{ flexDirection: "row", paddingVertical: 50 }}>
									<View style={{ flex: 2 }}>
										<StyledText style={{ fontSize: 34, fontWeight: "bold", marginBottom: 20 }}>Qué opinan los alumnos</StyledText>
										<View style={{ flexDirection: "row", alignItems: "center" }}>
											<View style={{ flexDirection: "row" }}>
												<Ionicons name="star" color="#ef6b52" size={30} style={{ marginRight: 5 }} />
												<Ionicons name="star" color="#ef6b52" size={30} style={{ marginRight: 5 }} />
												<Ionicons name="star" color="#ef6b52" size={30} style={{ marginRight: 5 }} />
												<Ionicons name="star" color="#ef6b52" size={30} style={{ marginRight: 5 }} />
												<Ionicons name="star" color="#ef6b52" size={30} style={{ marginRight: 0 }} />
											</View >
											<StyledText style={{ marginLeft: 10, marginTop: 5, fontSize: 18 }}>5 estrellas de 34 Reseñas</StyledText>
										</View>
									</View>
									<View style={{ flex: 1, marginLeft: 20 }}>
										<View style={{ width: "100%", borderRadius: 15, padding: 15, marginHorizontal: 5, marginTop: 25, backgroundColor: "#F9F9F9" }}>
											<StyledText style={{ fontSize: 24, fontWeight: "bold", marginBottom: 25 }}>Últimos completados</StyledText>
											<View style={{ flexDirection: "row", flexWrap: "wrap" }}>
												{["Autodescubrimiento", "Cosa dos", "Mejora", "Resiliencia", "Burros", "Leer", "Escribir", "Dos mas Dos no siempre es 4"].map((el, i) => {
													return (
														<View key={i} style={{ height: 30, zIndex: 2, margin: 5, paddingHorizontal: 15, borderRadius: 5, backgroundColor: "white", justifyContent: "center" }}>
															<StyledText style={{ fontSize: 14, fontWeight: "bold" }}>{el}</StyledText>
														</View>
													);
												})}
											</View>
										</View>
									</View>
								</View>
							) : null}
						</View>
					</View>
				}


				{dataCourses?.filter(el => el.id !== id)?.length === 0 ?
					null
					:
					<>
						<View style={{ flexDirection: "row", marginTop: 50, paddingHorizontal: 10, alignSelf: "center", maxWidth: 1150, width: "100%" }}>
							<View style={{ flex: 1 }}>
								<StyledText style={{ fontSize: 34, fontFamily: "title" }}>También te puede interesar</StyledText>
							</View>
						</View>
						<View style={{ paddingTop: 15, marginTop: 10, flexWrap: "wrap", flexDirection: "row", }}>
							{dataCourses?.filter(el => el.id !== id)?.slice(0, 4)?.map((el, i) => <CourseCardSquare key={i} onPress={() => navigation.navigate("PreviewCourseScreen", { tenantId: el.tenantId, id: el.id })} data={el} />)}
						</View>
					</>
				}

				<Footer limited={false} />
			</ScrollView>

		</>
	);
};

export default PreviewCourseScreen;


const CourseRegisterButton = ({ preInitDate, preEndDate, id, tenantId, courseExists, setRegister, isEnrollmentFull }) => {
	const navigation = useNavigation()
	const { userData, customization, impersonatedUser } = useContext(AuthContext)
	const screenWidth = useWindowDimensions().width;
	const isPhone = screenWidth < 1000
	const courseStatus = courseExists ? courseExists.status : null;

	if (impersonatedUser) {
		return (
			<Button disabled round={7}>
				Solo puedes registrarte con tu usuario
			</Button>
		)
	}
	if (preInitDate && preEndDate) {
		if (moment().isBetween(moment(preInitDate?.toDate ? preInitDate.toDate() : preInitDate), moment(preEndDate?.toDate ? preEndDate.toDate() : preEndDate))) {
			return (
				<Button
					onPress={() => courseExists ?
						courseStatus === "PENDINGREVIEW" ?
							navigation.navigate("MainTabNavigator", { screen: "HomeScreen" })
							:
							navigation.navigate("MainTabNavigator", { screen: "Course", params: { id } })
						:
						userData.id ? setRegister(true) : navigation.navigate("Login", { redirect: `${tenantId}-${id}` })
					}
					disabled={courseStatus === "REJECTED" || isEnrollmentFull && courseStatus !== "APPROVED"}
					style={{ marginLeft: isPhone ? 0 : 20 }}
					label={courseExists ? (courseStatus === "REJECTED" ? "Estas Rechazado en este Curso" : courseStatus === "PENDINGREVIEW" ? "Pendiente de Revisión" : "Acceder al Curso") : isEnrollmentFull ? "Plazas máximas alcanzadas" : "Preinscripción al Curso"}
					color={customization.mainColor} round={7}
				/>
			)
		} else if (moment().isAfter(moment(preEndDate?.toDate ? preEndDate.toDate() : preEndDate))) {
			return (
				<Button
					onPress={() => navigation.navigate("MainTabNavigator", { screen: "Course", params: { id } })}
					disabled={!courseExists || isEnrollmentFull}
					style={{ marginLeft: isPhone ? 0 : 20 }}
					label={courseExists ? (courseStatus === "REJECTED" ? "Estas Rechazado en este Curso" : courseStatus === "PENDINGREVIEW" ? "Pendiente de Revisión" : "Acceder al Curso") : "El periodo de preinscripción ha concluido"}
					color={customization.mainColor} round={7}
				/>
			)
		} else if (moment().isBefore(moment(preInitDate?.toDate ? preInitDate.toDate() : preInitDate))) {
			return (
				<Button
					onPress={() => navigation.navigate("MainTabNavigator", { screen: "Course", params: { id } })}
					disabled={!courseExists || courseStatus === "REJECTED" || isEnrollmentFull}
					style={{ marginLeft: isPhone ? 0 : 20 }}
					label={courseExists ? (courseStatus === "REJECTED" ? "Estas Rechazado en este Curso" : courseStatus === "PENDINGREVIEW" ? "Pendiente de Revisión" : isEnrollmentFull ? "" : "Acceder al Curso") : <CountdownLabel preInitDate={preInitDate} preEndDate={preEndDate} />}
					color={customization.mainColor} round={7}
				/>
			)
		}
	} else {
		return (
			<StyledText>Este curso no esta disponible para preinscribirse</StyledText>
		);
	}
}

const CountdownLabel = ({ preInitDate, preEndDate }) => {
	const [countdown, setCountdown] = useState(null)
	const [countdownText, setCountdownText] = useState(null)

	useEffect(() => {
		if (preInitDate && preEndDate) {
			if (moment().isBetween(moment(preInitDate?.toDate ? preInitDate.toDate() : preInitDate), moment(preEndDate?.toDate ? preEndDate.toDate() : preEndDate))) {
				setCountdownText("Preinscripción abierta")
			} else if (moment().isAfter(moment(preEndDate?.toDate ? preEndDate.toDate() : preEndDate))) {
				setCountdownText("Preinscripción cerrada")
			} else if (moment().isBefore(moment(preInitDate?.toDate ? preInitDate.toDate() : preInitDate))) {
				setCountdown(moment(preInitDate?.toDate ? preInitDate.toDate() : preInitDate))
			}
		}
	}, [preInitDate, preEndDate])

	useEffect(() => {
		if (countdown) {
			const interval = setInterval(() => {
				const duration = moment.duration(countdown.diff(moment()))
				const days = duration.days()
				const hours = duration.hours()
				const minutes = duration.minutes()
				const seconds = duration.seconds()
				setCountdownText(`${days} días ${hours} horas ${minutes} minutos ${seconds} segundos`)
			}, 1000)
			return () => clearInterval(interval)
		}
	}, [countdown])

	return countdownText
}

function renderCustomForm(type, closeCb, submitCb, initialData, course) {
	switch (type) {
		case 16:
			return <CustomFormCV closeCallback={closeCb} submitCallback={submitCb} initialData={initialData} />
		case 12:
			return <CustomFormDeclaracionJurada closeCallback={closeCb} submitCallback={submitCb} initialData={initialData} />
		case 51:
			return <CustomFormDeclaracionMedica closeCallback={closeCb} submitCallback={submitCb} initialData={initialData} course={course} />
		default:
			throw new Error("El tipo de formulario es incorrecto y/o no existe")
	}
}

function getNumberOfSteps(fields, isFree, hasPreinscriptionFee, discountAmount, discountApplied) {
	let numberOfSteps = 2;

	const steps = [
		{ label: "Datos personales", step: 0 },
		{ label: "Documentación", step: 1 },
	];

	if (fields.length > 0) {
		numberOfSteps++;
		steps.push({ label: "Datos Adicionales", step: 2 });
	}

	if (!isFree) {
		numberOfSteps++;
		steps.push({ label: "Pago Preinscripción", step: numberOfSteps - 1 });
	}

	if (hasPreinscriptionFee && !isFree) {
		numberOfSteps++;
	}

	if (isFree && hasPreinscriptionFee && discountApplied && discountAmount) {
		numberOfSteps++;
		steps.push({ label: "Pago Preinscripción", step: numberOfSteps - 1 });
	}

	return { steps, numberOfSteps };
}

const RegisterModal = ({ setOpen, course, courseId, preinscriptionData, paymentSuccess, functions }) => {

	// Eliminamos el tipo de descuento de porcentaje
	// Solo existen 2 tipos de descuentos: aplican o al precio de la preinscripcion o al precio del curso
	// Aplicamos el descuento a los pagos a plazos desde el ultimo plazo hacia atras
	const hasSinglePaymentMethod = (course?.paymentOptions || []).length === 1;

	const route = useRoute();
	const { tenantId, id } = route.params;
	const { customization, userData } = useContext(AuthContext)


	const storage = getStorage()
	const db = getFirestore()

	const applyDiscount = httpsCallable(functions, "applyDiscount");
	const courseRegistration = httpsCallable(functions, "courseRegistration");
	const checkIdNumber = httpsCallable(functions, "checkIdNumber")

	const [orderId, setOrderId] = useState(paymentSuccess ?? null);

	const paymentDocRef = (orderId || paymentSuccess) ? doc(db, "payments", orderId ?? paymentSuccess) : null

	const tempDataRef = doc(db, "tempData", userData?.id);

	const { data: tempData, isLoading: isLoadingTempData, isSuccess: isSuccessTempData } = useFirestoreDocumentData(["tempData", userData?.id], tempDataRef, {}, {
		enabled: !!userData.id,
		onError: e => console.log(e)
	});

	const { data, isError, isSuccess } = useFirestoreDocumentData(["paymentDoc", orderId], paymentDocRef, { subscribe: true }, {
		enabled: !!orderId,
		onError: e => console.log(e)
	})

	const [step, setStep] = useState(0)
	const [loading, setLoading] = useState(false);
	const [loadingDiscount, setLoadingDiscount] = useState(false);
	const [discountApplied, setDiscountApplied] = useState(false);

	const [openSubform, setOpenSubform] = useState(false);
	const [loadingNext, setLoadingNext] = useState(false);
	const [error, setError] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null)

	const sh = useWindowDimensions().height

	const isPhone = useMediaQuery({ query: '(max-width: 1000px)' })

	const imageRef = useRef<CropperRef>(null)
	const idFrontRef = useRef<CropperRef>(null)
	const idBackRef = useRef<CropperRef>(null)

	//Calculate the min age according to the startDate of the course
	const startCourse = new Date(course.initDate.seconds * 1000)


	const validationSchema = yup.object({
		discount: yup.object().shape({
			discountCode: yup.string().notRequired(),
			discountAmount: yup.number().notRequired(),
			preinscription: yup.boolean().notRequired(),
		}),
		name: yup.string().required("El nombre es Requerido").trim(),
		surname: yup.string().required("El apellido es Requerido").trim(),
		image: yup.string().required("La imagen del documento es Requerida"),
		phone: yup.string().required("El telefono es Requerido").min(7, "El teléfono debe tener al menos 7 dígitos").trim(),
		address: yup.string().required("La dirección es Requerida").min(3, "La dirección debe tener al menos 3 caracteres").trim(),
		birthDate: yup.date().typeError("Debes introducir una fecha válida").min(new Date("1920-01-01"), "La fecha no puede ser menor a 1920").max(new Date(startCourse.setFullYear(startCourse.getFullYear() - 16)), "Debes tener mas de 16 años al comenzar el curso").required("La fecha de nacimiento es Requerida"),
		nationality: yup.string().required("La nacionalidad es Requerida"),
		birthCountry: yup.string().required("El pais de nacimiento es Requerido"),
		birthProvince: yup.string().required("La provincia de nacimiento es Requerida"),
		gender: yup.string().oneOf(["male", "female"], "El género es Requerido").required("El género es Requerido"),
		province: yup.string().required("La provincia de residencia es Requerida"),
		town: yup.string().required("La ciudad de residencia es Requerida"),
		postalCode: yup.string().required("El código postal es Requerido").min(5, "Debe tener al menos 5 caracteres").max(10, "Debe tener 10 caracteres máximo").trim(),
		idFront: yup.string().required("Este campo es Requerido"),
		idBack: yup.string().required("Este campo es Requerido"),
		idExpirationDate: yup.date().typeError("Debes introducir una fecha válida").min(new Date(), "El documento de identidad no puede estar caducado").required("Este campo es Requerido"),
		// idNumber: yup.string().trim().test("validateId", "El Número de documento introducido no es válido", (ev, ctx) => {
		// 	const p = ValidateSpanishID.ValidateSpanishID(ev)
		// 	return ctx.parent.idType === "passport" ? true : p.valid
		// }).required("El número de identificación es obligatorio").trim(),
		// idType: yup.string().oneOf(["dni", "nie", "passport"], "El tipo de documento es obligatorio").required("El tipo de documento es obligatorio"),
		...preinscriptionData.reduce((acc, item) => {
			if (item.type.includes("form") || item.type.includes("16") || item.type.includes("12") || item.type.includes("51")) {
				return {
					...acc,
					[item.key]: yup.object().required("El formulario debe estar completado").test("formChecker", "El formulario debe estar completado", ev => {
						const t = ev && Object.keys(ev)?.length > 0
						return item.required ? t : true
					})
						.nullable()
				}
			} else {
				return { ...acc, [item.key]: getValidationType(item.type, "Este campo es obligatorio", item.required) }
			}
		}, {})
	});


	async function checkDiscountCode({ tenantId, courseId, code }) {

		if (!code) return alert("Debes introducir un código de descuento");

		setLoadingDiscount(true);

		try {
			const { data } = await applyDiscount({ tenantId, courseId, code, isPreinscription: true });

			if (data?.error) {
				setValue("discountCode", "");
				setValue("discountAmount", 0)
				setValue("discountPreinscription", false)
				// setValue("discount", { discountAmount: 0, discountCode: "", preinscription: undefined });
				setLoadingDiscount(false);
				alert(data?.message);
			} else {
				setValue("discountCode", data?.discountCode || "");
				setValue("discountAmount", data?.discountAmount || "")
				setValue("discountPreinscription", data?.preinscription || false);
				// setValue("discount", { discountAmount: data.discountAmount, discountCode: data.discountCode, preinscription: data.preinscription });
				setLoadingDiscount(false);
				setDiscountApplied(true);
			}

		} catch (err) {
			setLoadingDiscount(false);
			console.log({ err })
		}
	}


	const formatDate = (date) => {
		if (date instanceof Timestamp) return moment(date?.toDate()).format("YYYY-MM-DD")
		if (date instanceof Date) return moment(date).format("YYYY-MM-DD")
		if (typeof date === "object" && date?.seconds) return moment(date?.seconds * 1000).format("YYYY-MM-DD")
		if (typeof date === "string") return moment(date).format("YYYY-MM-DD")
		return ""
	}


	const initialValues = {
		image: userData.image || "",
		discount: {
			discountCode: "",
			discountAmount: 0,
			preinscription: false,
		},
		name: userData.name || "",
		surname: userData.surname || "",
		phone: userData.phone || "",
		gender: userData.gender || "",
		email: userData.email || "",
		birthDate: formatDate(userData.birthDate),
		nationality: userData.nationality || "ESP",
		birthCountry: userData.birthCountry || "ESP",
		birthProvince: userData.birthProvince || "",
		address: userData.address || "",
		province: userData.province || "",
		town: userData.town || "",
		postalCode: userData.postalCode || userData.cp || "",
		idType: (userData.idType || "").toLowerCase(),
		idFront: userData.idFront || userData.idCardFront || "",
		idBack: userData.idBack || userData.idCardBack || "",
		idNumber: userData.idNumber || userData.idCardNumber || "",
		idExpirationDate: formatDate(userData.idExpirationDate),
		discountCode: "",
		discountAmount: 0,
		discountPreinscription: false,
		...preinscriptionData.reduce((acc, el) => ({
			...acc,
			[el.key]: userData[el.key] || null
		}), {}),
		paymentMethod: hasSinglePaymentMethod ? (_.head(course?.paymentOptions)?.id || "") : "",
	}

	const resolver = useYupValidationResolver(validationSchema);

	const {
		handleSubmit,
		setValue,
		getValues,
		watch,
		trigger,
		reset,
		control,
		setError: setFormError,
		formState: { errors },
	} = useForm({
		mode: "onChange",
		resolver,
		defaultValues: initialValues,
	})

	const discountCode = watch("discountCode");
	const discountAmount = watch("discountAmount");
	const discountPreinscription = watch("discountPreinscription");

	const birthCountry = watch("birthCountry")
	const province = watch("province")
	const paymentMethod = watch("paymentMethod")
	const selectedPaymentMethod = watch("paymentMethod")
	const idType = watch("idType")
	const idNumber = watch("idNumber")

	useEffect(() => {
		if (isSuccessTempData) {
			const isNewerData = userData?.updatedAt?.toDate().getTime() < tempData?.updatedAt?.toDate().getTime()
			const bootstrap = isNewerData ? _.merge(initialValues, tempData || {}) : initialValues;

			const finalValues = {
				...bootstrap,
				idNumber: (bootstrap.idNumber || userData.idNumber)?.toLocaleUpperCase().replace(/[^A-Z0-9]+/ig, ""),
				idType: (bootstrap.idType || userData.idType || "")?.toLowerCase(),
				birthDate: formatDate(bootstrap.birthDate),
				idExpirationDate: formatDate(bootstrap.idExpirationDate),
			}

			reset(finalValues);
		}
	}, [isSuccessTempData])


	const uploadImage = (uri, name) => {
		return new Promise((resolve, reject) => {
			const file = ref(storage, `users/${userData.id}/${name}`)
			uploadString(file, uri.replace("data:image/png;base64,", ""), "base64", {
				contentType: "image/png",
			}).then(res => {
				resolve(file.fullPath)
			}).catch(err => {
				// console.log(err)
				reject(err)
			})
		});
	}

	async function makeRedsysPayment({ formData }) {

		const methodRef = doc(db, "tenants", course.tenantId, "paymentMethods", selectedPaymentMethod)
		const d = (await getDoc(methodRef)).data()
		const form = document.createElement("form")
		if (!d?.endpoint) return
		form.setAttribute("name", "from")
		form.setAttribute("method", "post")
		form.setAttribute("target", "_self")
		form.setAttribute("action", d.endpoint)
		form.setAttribute("style", "display: none")
		Object.keys(formData)
			.forEach(key => {
				const input = document.createElement("input")
				input.setAttribute("type", "hidden")
				input.setAttribute("name", key)
				input.setAttribute("value", formData[key])
				form.appendChild(input)
			})

		const input = document.createElement("input")
		input.setAttribute("type", "submit")
		input.setAttribute("value", "Pay with credit card")
		form.appendChild(input)
		document.body.appendChild(form)
		form.submit()
		document.body.removeChild(form)
	}


	const submitForm = async (values) => {
		setLoading(true)


		const paymentMethod = course?.paymentOptions?.find(el => el.id === selectedPaymentMethod) || course?.paymentOptions[0] || ""
		const paymentMethodId = (paymentMethod?.id || "").trim()

		try {
			const i = imageRef?.current?.getCanvas()?.toDataURL()
			const iff = idFrontRef?.current?.getCanvas()?.toDataURL()
			const ib = idBackRef?.current?.getCanvas()?.toDataURL()

			const p = await uploadImage(i, `image_${new Date().valueOf()}.png`)
			const piff = await uploadImage(iff, `idFront_${new Date().valueOf()}.png`)
			const pib = await uploadImage(ib, `idBack_${new Date().valueOf()}.png`)


			const sanitizedValues = sanitizeObject(values)
			const formValues = discountApplied ? sanitizedValues : omit(sanitizedValues, ["discountCode", "discountAmount", "discountPreinscription"])

			const finalValues = {
				..._.pick(formValues, [
					"address",
					"birthCountry",
					"birthDate",
					"birthProvince",
					"gender",
					"idExpirationDate",
					"idNumber",
					"idType",
					"name",
					"nationality",
					"phone",
					"postalCode",
					"province",
					"surname",
					"email",
					"town",
					...(preinscriptionData.map(x => x.key) || [])
				]),
				updatedAt: serverTimestamp(),
				image: p,
				idFront: piff,
				idBack: pib,
				idNumber: (values.idNumber || "")?.toLocaleUpperCase().replace(/[^A-Z0-9]+/ig, ""),
				birthDate: new Date(values.birthDate),
				idExpirationDate: new Date(values.idExpirationDate),
				tenantId: course.tenantId,
				courseId: courseId,
			}

			setDoc(tempDataRef, finalValues, { merge: true })

			const v = {
				...finalValues,
				paymentMethodId,
				discountCode: values.discountCode,
				originUrl: `/fed/${tenantId}/curso/${id}`
			}


			const { data } = await courseRegistration(v)



			if (data?.tpvType === "redsys") {

				const { orderId, form } = data;
				setOrderId(orderId, form);
				makeRedsysPayment({ formData: form })
			}

			if (data?.tpvType === "addonpayments") {
				const { orderId, hppPayByLink } = data
				setOrderId(orderId)
				location.href = hppPayByLink;
			}

			if (data.tpvType === "cecabank") {
				const { form, orderId, action } = data;
				setOrderId(orderId)
				makeCecabankPayment({ formData: { ...form, action } })
			}

			if (data?.completed) {
				setLoading(false)
				setStep(4);
			}

			if (data?.full) {
				setLoading(false)
				setStep(0);
				setError(true)
				setErrorMessage(data?.message ?? "No quedan plazas disponibles")
			}

		} catch (err) {
			console.log({ err })
			setError(true)
			setErrorMessage("Se ha producido un error inesperado al intentar realizar el registro en el curso")
			setLoading(false)
		}
	}


	async function recoverTempFormData(keys) {
		const form = getValues();
		const values = _.pick(form, keys);

		try {
			setDoc(tempDataRef, {
				..._.omit(values, ['image', 'idBack', 'idFront']),
				createdAt: serverTimestamp(),
				updatedAt: serverTimestamp()
			}, { merge: true })

		} catch (err) {
			console.log("Error saving temp data", err)
		}

	}


	const canGoNextStep = () => {
		setLoadingNext(true)

		const fieldsStep0 = ["name", "surname", "image", "birthDate", "phone", "gender", "nationality", "nationality", "birthCountry", "birthProvince", "address", "province", "town", "postalCode"]
		const fieldsStep1 = ["idType", "idNumber", "idBack", "idFront", "idExpirationDate"]
		const fieldsStep2 = preinscriptionData.map((field) => field.key) || []
		const fieldsStep3 = ["paymentMethod"]

		const fieldToValidate = step === 0 ? fieldsStep0 : step === 1 ? fieldsStep1 : step === 2 ? fieldsStep2 : fieldsStep3

		trigger(fieldToValidate)
			.then((res) => {
				if (res) {
					if (step === 1 && !userData.idNumber) {
						const idNumber = getValues("idNumber")
						checkIdNumber({ idNumber })
							.then(() => {
								setStep(step + 1)
								setLoadingNext(false)
							}).catch(err => {
								setFormError("idNumber", { message: err.message }, { shouldFocus: true })
								setLoadingNext(false)
							})
					} else {
						step < 3 && recoverTempFormData(fieldToValidate);
						setStep(step + 1)
						setLoadingNext(false)
					}
				} else {
					setLoadingNext(false)
				}
			}).catch(err => {
				setLoadingNext(false)
			})
	};

	function hasAdditionalContent(fields, step) {
		return fields.length > 0 ? step === 3 : step === 2
	}

	useEffect(() => {
		if (isSuccess && data) {
			if (data?.status === "PAID" && orderId && !isError) {
				setStep(4)
				setLoading(false)
				setError(null)
			} else if (data?.status === "ERROR" && !isError) {
				setError(true)
				setErrorMessage("Ha ocurrido un error al procesar el pago de tu preinscripción")
				setStep(4)
				setLoading(false)
			}
		}
	}, [data, isError, isSuccess, orderId])

	useEffect(() => {
		let timeout;
		if (loading) {
			timeout = setTimeout(() => {
				setLoading(false)
				setError(true)
				setErrorMessage("Ha transcurrido demasiado tiempo a la hora de llevar a cabo esta operación")
				setStep(4)
			}, 60000)
		}
		return () => clearTimeout(timeout)
	}, [loading])

	const isFree = course?.preinscriptionFee === 0 || (clamp(course?.preinscriptionFee - ((discountPreinscription ? discountAmount : 0) || 0), 0, course?.preinscriptionFee) || 0) <= 0

	const { steps } = getNumberOfSteps(preinscriptionData, isFree, course?.preinscriptionFee > 0, discountAmount, discountApplied)

	const aditionalContent = hasAdditionalContent(preinscriptionData, step)
	const isLastStep = step === steps.length - 1


	return (
		<Modal modalContainerStyle={{ height: isPhone && step !== 4 ? "100%" : undefined, maxHeight: isPhone ? "100%" : sh - 50, width: "100%", maxWidth: 750 }} duration={100} padding={0} >
			{loading || isLoadingTempData ?
				<View style={{ position: "absolute", top: 0, left: 0, bottom: 0, right: 0, zIndex: 10, borderRadius: 10, backgroundColor: "white", alignItems: "center", justifyContent: "center", }}>
					<ActivityIndicator color={customization.mainColor} size="large" />
					<StyledText style={{ fontSize: 34, fontFamily: "Title", marginTop: 20 }}>Comprobando información</StyledText>
				</View>
				: null
			}

			<View style={{ width: "100%" }}>
				{/* STEPPER */}
				{step !== 4 ? <View style={{ padding: 15 }}>
					<Stepper steps={steps} onPress={(ev) => setStep(ev)} step={step} />
				</View> : null}


				<ScrollView style={{ maxHeight: isPhone ? sh - 150 : sh - 220, flex: 1 }} contentContainerStyle={{ paddingHorizontal: 15, flexGrow: 1 }}>
					{/* DATOS PERSONALES */}
					<View
						pointerEvents={step === 0 ? "auto" : "none"}
						style={{ position: step === 0 ? "relative" : 'absolute', overflow: "hidden", height: step === 0 ? "auto" : 0, opacity: step === 0 ? 1 : 0 }} >
						<ImageField
							ref={imageRef}
							control={control}
							name="image"
							key="image"
							label="Foto de Perfil (Fondo Blanco)"
							error={errors["image"]}
							initialValue={userData.image}
							height={300}
						/>
						<View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
							<TextField error={get(errors, "name", undefined)} name={"name"} key="name" label="Nombre" placeholder="Nombre..." control={control} />
							<TextField error={get(errors, "surname", undefined)} containerProps={{ style: { marginLeft: 10, flex: 1 } }} name={"surname"} key="surname" label="Apellidos" placeholder="Apellidos..." control={control} />
						</View>
						<View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
							<TextField error={get(errors, "birthDate", undefined)} type="date" name={"birthDate"} key="birthDate" label="Fecha de Nacimiento" placeholder="Fecha de Nacimiento..." control={control} />
							<TextField error={get(errors, "phone", undefined)} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} name={"phone"} key="phone" label="Teléfono" placeholder="Teléfono..." control={control} />
							<SelectField error={get(errors, "gender", undefined)} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} defaultValue={undefined} options={[{ label: "Hombre", value: "male" }, { label: "Mujer", value: "female" }]} labelKey="label" valueKey={"value"} name={"gender"} key="gender" label="Género" placeholder="Género..." control={control} />
						</View>
						<View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
							<SelectField error={get(errors, "nationality", undefined)} options={countries} valueKey="code" labelKey="name" name={"nationality"} key="nationality" label="Nacionalidad" placeholder="Nacionalidad..." control={control} />
							<SelectField error={get(errors, "birthCountry", undefined)} containerProps={{ style: { marginLeft: 10, flex: 1 } }} options={countries} valueKey="code" labelKey="name" name={"birthCountry"} key="birthCountry" label="Pais de Nacimiento" placeholder="Pais de Nacimiento..." control={control} />
						</View>
						<View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
							{birthCountry === "ESP" ?
								<SelectField error={get(errors, "birthProvince", undefined)} defaultValue={undefined} options={provinces} valueKey="provincia" labelKey="provincia" name={"birthProvince"} key="birthProvince" label="Provincia de Nacimiento" placeholder="Provincia de Nacimiento..." control={control} />
								:
								<TextField error={get(errors, "birthProvince", undefined)} name={"birthProvince"} key="birthProvince" label="Provincia de Nacimiento" placeholder="Provincia de Nacimiento..." control={control} />
							}
						</View>

						<View style={{ width: "100%", flexDirection: "row", marginBottom: 15 }}>
							<TextField error={get(errors, "address", undefined)} name={"address"} key="address" label="Dirección actual" placeholder="Dirección..." control={control} />
						</View>
						<View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
							<SelectField error={get(errors, "province", undefined)} options={provinces} valueKey="provincia" labelKey="provincia" name={"province"} key="province" label="Provincia" placeholder="Provincia..." control={control} />
							<SelectField error={get(errors, "town", undefined)} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} options={provinces.find(el => el.provincia === province)?.localidades || []} name={"town"} key="town" label="Localidad" placeholder="Localidad..." control={control} />
							<TextField error={get(errors, "postalCode", undefined)} containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }} name={"postalCode"} key="postalCode" label="Código Postal" placeholder="Código Postal..." control={control} />
						</View>
					</View>

					{/* DATOS DE DNI */}
					<View
						pointerEvents={step === 1 ? "auto" : "none"}
						style={{ position: step === 1 ? "relative" : 'absolute', overflow: "hidden", height: step === 1 ? "auto" : 0, opacity: step === 1 ? 1 : 0 }}>
						<View style={{ width: "100%", flexDirection: isPhone ? "column" : "row", marginBottom: 15 }}>
							<SelectField
								disabled={idType !== ""}
								error={get(errors, "idType", undefined)}
								labelKey={"label"}
								valueKey={"value"}
								options={[{ label: "DNI", value: "dni" }, { label: "NIE", value: "nie" }, { label: "Pasaporte", value: "passport" }]}
								name={"idType"}
								key="idType"
								label="Tipo de Documento"
								placeholder="Tipo de Documento..."
								control={control}
							/>
							<TextField
								error={get(errors, "idExpirationDate", undefined)}
								containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }}
								type="date"
								name={"idExpirationDate"}
								key="idExpirationDate"
								label="Fecha de Validez"
								placeholder="Fecha de Validez..."
								control={control}
							/>
							<TextField
								disabled={idNumber !== ""}
								error={get(errors, "idNumber", undefined)}
								containerProps={{ style: { marginLeft: isPhone ? 0 : 10, marginTop: isPhone ? 10 : 0, flex: 1 } }}
								name={"idNumber"}
								key="idNumber"
								label="Número de Documento"
								placeholder="Número de documento..."
								control={control}
							/>
						</View>
						<View style={{ flexDirection: isPhone ? "column" : "row", }}>
							<ImageField
								ref={idFrontRef}
								control={control}
								name="idFront"
								key="idFront"
								label="Frontal"
								error={errors["idFront"]}
								initialValue={userData.idFront}
								stencilProps={{
									movable: true,
									resizable: true,
								}}
								height={250}
								containerProps={{
									style: {
										width: 350,
										height: 300
									}
								}}
								zoomButtons={true}
							/>
							<ImageField
								ref={idBackRef}
								control={control}
								containerProps={{
									style: {
										marginLeft: isPhone ? 0 : 10,
										width: 350,
										height: 300
									}
								}}
								zoomButtons={true}
								stencilProps={{
									movable: true,
									resizable: true,
								}}
								height={250}
								name="idBack"
								key="idBack"
								initialValue={userData.idBack}
								label="Trasera"
								error={errors["idBack"]}
							/>
						</View>
					</View>

					{/* DATOS DE PREINSCRIPCION */}
					{preinscriptionData?.length > 0 ?
						<View
							pointerEvents={step === 2 ? "auto" : "none"}
							style={{ position: step === 2 ? "relative" : 'absolute', overflow: "hidden", height: step === 2 ? "auto" : 0, opacity: step === 2 ? 1 : 0 }} >
							<View style={{ width: "100%" }}>
								<StyledText style={{ fontSize: 34, fontFamily: "Title" }}>Datos Adicionales</StyledText>
								{preinscriptionData?.map((field, idx) => {

									if (field.type && field.key) {
										return field.type.includes("form") || field.type.includes("16") || field.type.includes("12") || field.type.includes("51")
											?
											<View key={idx} style={{ width: "100%", paddingBottom: 10, }}>
												<View key={`${field.name}`} >
													<StyledText style={{ fontSize: 16, color: "gray" }}>{field.name}</StyledText>
													<View style={{ flexDirection: "row", alignItems: "center", flex: 1, marginVertical: 10 }}>
														<Button
															round={5}
															style={{ flex: 1 }}
															height={35}
															label={getValues(field.key) ? "Modificar" : "Editar"}
															onPress={() => {
																const form = getValues(field.key);
																const defaultValues = form ? form : {};
																setOpenSubform({
																	key: field.key,
																	formId: field.id,
																	formRef: field.ref,
																	index: idx,
																	defaultValues,
																	documentType: (field.type === "form-NrCcaX760nhT7MsGnn3C" || field.type.includes("16")) ? 16 : (field.type === "form-g9BGWNtCXMVF3PZtya5X" || field.type.includes("12")) ? 12 : (field.type === "form-Rs0Uvb149qAQ5jihS55A" || field.type.includes("51")) ? 51 : field.documentType
																})
															}}
														/>

														{getValues(field.key) ?
															<FontAwesome name="check" color="#e4002b" size={20} style={{ marginLeft: 10 }} />
															:
															null
														}
													</View>
												</View>

												{get(errors, field.key) ? (
													<StyledText style={{ marginTop: 10, fontSize: 14, color: "red" }}>
														{get(errors, field.key)?.message}
													</StyledText>
												) : null}
											</View>
											:
											<Fragment key={idx}>
												<MultiField
													name={field.key}
													error={errors[field.key]}
													route={`preinscriptionDocuments/${course.tenantId}/course/${courseId}/${userData?.id}`}
													label={field.name}
													type={field.type}
													required={field.required}
													customRef={field.ref}
													containerProps={{ style: { marginBottom: 10 } }}
													setValue={value => setValue(field.key, value)}
													control={control}
												/>
												{field.template ?
													<TouchableOpacity onPress={() => {
														getDownloadURL(ref(storage, field.template)).then(res => {
															window.open(res, "_blank")
														}).catch(err => {
															console.log({ err })
															alert("No se ha podido descargar el archivo")
														})
													}} style={{ marginBottom: 15, flexDirection: "row", alignItems: "center", columnGap: 5, paddingHorizontal: 15, paddingVertical: 10, backgroundColor: "#f2f2f2" }}>
														<Ionicons name="download-outline" size={20} />
														<StyledText style={{ fontSize: 16, color: "black" }}>Descargar plantilla {field.name}</StyledText>
													</TouchableOpacity>
													: null
												}
											</Fragment>
									}
								})}
							</View>
						</View>
						: null
					}

					{/* FORMULARIOD DE PAGO PREINSCRIPCION */}
					<View
						pointerEvents={aditionalContent ? "auto" : "none"}
						style={{ position: aditionalContent ? "relative" : 'absolute', overflow: "hidden", height: aditionalContent ? "auto" : 0, opacity: aditionalContent ? 1 : 0 }} >
						<View style={{ flex: 1, width: "100%" }}>
							<View style={{ paddingTop: 20 }}>
								<StyledText style={{ fontSize: 16 }}>
									El coste de la preinscripción se deberá abonar mediante el método de pago seleccionado en este formulario. Una vez verificados tus datos de preinscripción se te comunicará si has sido aceptado en el programa.
									Una vez aceptado deberás proceder al pago total del curso en los plazos acordados. El coste total del programa incluida la preinscripción es de {(course?.programFee || 0)} €
								</StyledText>
							</View>

							{!discountApplied
								? <View style={{ flex: 1, width: "100%", flexDirection: "row", alignItems: 'center', marginVertical: 20 }}>
									<TextField
										editable={"false"}
										containerProps={{ style: { flex: 1, opacity: (loadingDiscount || discountApplied) ? 0.5 : 1 } }}
										name={"discountCode"}
										key="discount"
										label="Código de descuento"
										placeholder="Descuento..."
										control={control}
									/>
									<Button
										primary
										disabled={loadingDiscount || discountApplied}
										style={{ marginLeft: 10, width: 100, alignSelf: "flex-end" }}
										height={45}
										round={7}
										label={"Aplicar"}
										onPress={() => checkDiscountCode({ tenantId: course.tenantId, courseId, code: getValues("discountCode") })}
									/>
								</View>
								:
								<>
									<View style={{ flex: 1, width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "gainsboro", marginTop: 20, marginBottom: 20, borderRadius: 5, padding: 15 }}>
										<View style={{ justifyContent: "space-evenly", height: "100%" }}>
											<StyledText style={{ fontSize: 16, paddingBottom: 10 }}>{`Descuento`}</StyledText>
											<StyledText style={{ fontSize: 16, fontFamily: 'DemiBold' }}>{`${discountCode}`}</StyledText>
										</View>
										<StyledText style={{ fontSize: 20, fontFamily: "DemiBold" }}>{`${discountAmount} €`}</StyledText>
									</View>
									<StyledText style={{ marginBottom: 10, fontSize: 16, color: "gray" }}>
										{`Descuento aplicado ${discountPreinscription ? 'a la preinscripción' : 'al programa'}`}
									</StyledText>
								</>
							}

							{course?.paymentOptions?.find(el => el.id === selectedPaymentMethod)?.paymentMethod === "BANK"}

							{(clamp(course?.preinscriptionFee - ((discountPreinscription ? discountAmount : 0) || 0), 0, course?.preinscriptionFee) || 0) > 0
								&& <View style={{ width: "100%", marginTop: 20, marginBottom: 15 }}>
									{
										hasSinglePaymentMethod
											? (
												<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
													<StyledText style={{ color: "gray", fontSize: 16, marginBottom: 10 }}>
														Método de pago
													</StyledText>
													<StyledText style={{ fontSize: 16, fontFamily: 'DemiBold' }}>{_.head(course?.paymentOptions)?.name}</StyledText>
												</View>
											)
											:
											<SelectField
												containerProps={{ style: { flex: 1 } }}
												options={course.paymentOptions}
												labelKey={"name"}
												valueKey={"id"}
												name={"paymentMethod"}
												key="paymentMethod"
												label="Selecciona el método de Pago"
												placeholder="Método de Pago..."
												control={control}
												moreInfo={undefined}
											/>
									}
									{(course?.paymentOptions || [])?.find(p => p.id === selectedPaymentMethod)?.paymentMethod === "BANK TRANSFER" &&
										<>
											<View style={{ padding: 15, marginTop: 25, backgroundColor: "#f3f3f3", borderRadius: 5 }}>
												{course?.paymentOptions?.find(el => el.id === paymentMethod)?.description && (
													<StyledText style={{ fontSize: 16 }}>
														{course?.paymentOptions?.find(el => el.id === paymentMethod)?.description}
													</StyledText>
												)}
												{course?.paymentOptions?.find(el => el.id === paymentMethod)?.iban && (
													<View style={{ marginTop: 10, backgroundColor: "white", padding: 10, borderRadius: 5 }}>
														<StyledText style={{ fontSize: 18 }}>IBAN: {course?.paymentOptions?.find(el => el.id === paymentMethod)?.iban}</StyledText>
													</View>
												)}
											</View>
										</>}
								</View>
							}

							<View style={{ padding: 15, marginTop: 5, backgroundColor: "#061934", borderRadius: 5, flexDirection: "row" }}>

								<View style={{ flex: 1 }}>
									<StyledText style={{ fontSize: 26, fontFamily: "Title", color: "white" }}>Preinscripción a abonar</StyledText>
								</View>
								<View>
									<StyledText style={{ fontSize: 26, fontFamily: "Title", color: "white" }}>
										{(clamp(course?.preinscriptionFee - ((discountPreinscription ? discountAmount : 0) || 0), 0, course?.preinscriptionFee) || 0)}€
									</StyledText>
								</View>
							</View>
						</View>
					</View>


					{/* REGISTRO CORRECTO */}
					{!Boolean(error)
						? <View pointerEvents={step === 4 ? "auto" : "none"} style={{ position: step === 4 ? "relative" : 'absolute', overflow: "hidden", height: step === 4 ? "auto" : 0, opacity: step === 4 ? 1 : 0 }} >
							<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
								<Ionicons name={"checkmark-circle-outline"} color={"green"} size={120} />
								<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>¡Tu preinscripción se ha realizado con éxito!</StyledText>
								{/* <StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
									Tu preinscripción se ha procesado correctamente, revisaremos tus datos y te enviaremos un correo electrónico con la confirmación de tu preinscripción.
								</StyledText> */}
								{paymentMethod && course?.paymentOptions?.find(el => el.id === paymentMethod)?.paymentMethod !== "TPV" ?
									<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>
										Tu preinscripción se ha procesado correctamente, revisaremos tus datos y te enviaremos un correo electrónico con la confirmación de tu preinscripción.
									</StyledText>
									: null
								}

								<View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
									<Button style={{ marginRight: 10 }} round={7} label="Mis Programas" primary onPress={() => {
										setOpen(false)
										window.location.href = `/alumno/cursos`
										// navigation.navigate("MainTabNavigator", { screen: "MainTabNavigator", params: { screen: "SmartHome" } })
									}} />
								</View>
							</View>
						</View>
						:
						<View pointerEvents={step === 4 ? "auto" : "none"} style={{ position: step === 4 ? "relative" : 'absolute', overflow: "hidden", height: step === 4 ? "auto" : 0, opacity: step === 4 ? 1 : 0 }} >
							<View style={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
								<MaterialIcons name={"error-outline"} color={customization.mainColor} size={120} />
								<StyledText style={{ fontSize: 34, fontFamily: "Title", textAlign: "center" }}>Se ha producido un error</StyledText>
								<StyledText style={{ textAlign: "center", fontSize: 16, maxWidth: 500, marginVertical: 15, fontFamily: "DemiBold" }}>{errorMessage}</StyledText>
								<View style={{ flexDirection: "row", marginTop: 10, marginBottom: 30, alignItems: "center", justifyContent: "center" }}>
									<Button
										style={{ marginRight: 10 }}
										round={7}
										label="Volver"
										primary
										onPress={() => setStep(prevStep => prevStep - 1)}
									/>
								</View>
							</View>
						</View>
					}
				</ScrollView>
			</View >

			{/* BOTONES INFERIORES */}
			{step !== 4 ?
				<View
					style={{ padding: 15, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
					<Button
						disabled={loading || loadingNext}
						onPress={() => step === 0 ? setOpen(false) : setStep(step - 1)}
						label={step === 0 ? "Cerrar" : "Volver"}
						round={5}
						height={40}
						color="gainsboro"
					/>
					<Button
						loading={loadingNext}
						disabled={loadingNext || loading || (isLastStep && !isFree && (selectedPaymentMethod === "" || selectedPaymentMethod === "Método de Pago..."))}
						onPress={isLastStep ? handleSubmit(submitForm) : () => canGoNextStep()}
						label={loading ? "Cargando.." : isLastStep ? "Completar" : "Siguiente"}
						round={5}
						height={40}
					/>

				</View>
				: null
			}

			{/* MODAL CUSTOM DATOS PREINSCRIPCION */}
			{(step === 2 && openSubform)
				? ((openSubform?.documentType === 16 || openSubform?.documentType === 12 || openSubform?.documentType === 51)
					? renderCustomForm(
						openSubform.documentType,
						() => setOpenSubform(false),
						(data) => {
							setValue(openSubform?.key, { ...data })
							setOpenSubform(false)
						},
						openSubform.defaultValues,
						course,
					)
					: <Modal padding={0} modalContainerStyle={{ maxWidth: 900, width: "95%", backgroundColor: "white", borderRadius: 7 }} >
						<Subform
							defaultValues={openSubform.defaultValues}
							formRef={openSubform?.formRef}
							update={(ev) => setValue(openSubform?.key, ev)}
							fieldRoute={`preinscriptionDocuments/${course.tenantId}/course/${courseId}/${userData?.id}`}
							index={openSubform.index}
							formId={openSubform.formId}
							onClose={() => setOpenSubform(false)}
						/>
					</Modal>
				)
				: null}
		</Modal>
	)
}
