import { Ionicons } from '@expo/vector-icons';
import { useFirestoreQuery } from '@react-query-firebase/firestore';
import { LinearGradient } from 'expo-linear-gradient';
import { getAuth } from 'firebase/auth';
import { collection, doc, getFirestore, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, StyleSheet, TouchableOpacity, View } from 'react-native';
import ReactPlayer from 'react-player';
import Button from '../common/Button';
import Modal from '../common/Modal';
import StyledText from '../common/StyledText';

function secondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const remainingSecondsFinal = remainingSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSecondsFinal.toString().padStart(2, "0")}`;
}
function secondsToMMSS(seconds) {
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const remainingSecondsFinal = remainingSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSecondsFinal.toString().padStart(2, "0")}`;
}


// PONER UN MENSAJE AL COMENZAR EL VIDEO DICIENDO QUE VA A TENER 3 PRUEBAS 

// ANTES DEL PLAZO NO SE PUEDE VER
// DESPUES DEL PLAZO SI SE PUEDE VER HAYAS APROBADO O SUSPENDIDO
// DURANTE EL PLAZO HAS APROBADO PUEDES VERLO CUANTAS VECES QUIERAS PERO NO REGISTRA

// SI SE HA SUSPENDIDO EN EL MOMENTO DEL SUSPENSO, SE PARA EL VIDEO Y APARECE UN MENSAJE PARA REINICIAR EL INTENTO

// PONER BOTON DE REINICIAR QUE LANZA UN MENSAJE

// PONER AL FINAL UN BOTON DE COMPLETAR LA ACTIVIDAD




const ControledVideo = ({ uri, times, content, advanceRef, contentId }) => {




    const numberOfChecks = 3
    const [loading, setLoading] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [areYouThere, setAreYouThere] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [deadline, setDeadline] = useState(null)
    const [deadlineAdvance, setDeadlineAdvance] = useState(null)
    const [approved, setApproved] = useState(false)

    const [restartModal, setRestartModal] = useState(false)
    const [firstMessage, setFirstMessage] = useState(false)
    const [completeModal, setCompleteModal] = useState(false)

    const [timesToCheck, setTimesToCheck] = useState(times || [])
    const [checks, setChecks] = useState([])
    const checkRefs = useRef([])
    const [file, setFile] = useState(null)
    const storage = getStorage()
    const videoRef = useRef()
    const auth = getAuth()

    const db = getFirestore()

    const currentDeadline = !content?.deadlines ? null : content?.deadlines?.length === 0 ? null : content?.deadlines?.find((el) => el?.start?.toDate() < Date.now() && el?.end?.toDate() > Date.now())
    const allDeadlinesHavePassed = !content?.deadlines ? false : content?.deadlines?.length === 0 ? false : content?.deadlines?.every((el) => el?.end?.toDate() < Date.now())
    const noDeadlineHasStarted = !content?.deadlines ? true : content?.deadlines?.length === 0 ? true : content?.deadlines?.every((el) => el?.start?.toDate() > Date.now())


    const contentAdvance = {
        grade: null,
        comments: null,
        newUpdate: false,
        lastCorrection: null,
        correctionBy: null,

        name: content.title,
        contentId: content.id,
        contentType: "TASK",

        tenantId: advanceRef.path.split("/")[1],

        courseName: "",
        courseTag: "",
        courseId: "",

        // User that submitted the deadline advance
        userId: auth.currentUser.uid,
        userImage: "",
        userName: "",
        userSurname: "",

        teachers: [],
        status: null,
    }

    const advancesRef = query(collection(db, `${advanceRef?.path}/contents`), where("contentId", "==", contentId))

    const { data, error, isLoading } = useFirestoreQuery(['advanceContent', contentId], advancesRef, {
        // enabled: duration > 0,
        enabled: videoRef.current
    }, {
        // refetchOnMount: "always",
        select: (data) => data?.docs?.map((el) => ({ ...el?.data(), deadlineId: el?.data()?.deadlineId ? el?.data()?.deadlineId : el?.id })),
        onSuccess: (data) => {

            if (playing) {
                // console.log("FETCH BUT PLAYING")
                return
            }

            if (!content?.deadlines || content?.deadlines?.length === 0) {
                //If there are no deadlines, the content is completed
                // console.log("CONTENT DONT HAVE ANY DEADLINES")
                setCompleted(false)
            } else if (data?.some((el) => el?.status === "APPROVED")) {
                //If there are deadlines, but it is already completed
                // console.log("CONTENT IS COMPLETED")
                setApproved(true)
                const lastDeadlineApproved = data?.find((el) => el?.status === "APPROVED")
                setDeadline(lastDeadlineApproved.deadlineId)
                setChecks(lastDeadlineApproved.checks)
                checkRefs.current = lastDeadlineApproved.checks
                setTimesToCheck(lastDeadlineApproved.timesToCheck)
                const deadlineAdvanceRef = doc(db, `${advanceRef.path}/contents/${lastDeadlineApproved.id}`)
                setDeadlineAdvance(deadlineAdvanceRef)
                setCompleted(true)
            } else {


                if (!currentDeadline) {
                    //If there are deadlines, but none of them are active
                    setCompleted(false)
                    return
                }
                const allDeadlinesHavePassed = content?.deadlines?.every((el) => el?.end?.toDate() < Date.now())


                if (!currentDeadline || allDeadlinesHavePassed) {
                    //If there are deadlines, but none of them are active
                    setCompleted(true)

                } else {

                    setDeadline(currentDeadline.id)
                    const deadlineAdvance = data?.find((el) => el?.deadlineId === currentDeadline?.id)

                    if (deadlineAdvance) {
                        const deadlineAdvanceRef = doc(db, `${advanceRef.path}/contents/${deadlineAdvance.id}`)
                        setDeadlineAdvance(deadlineAdvanceRef)
                        setTimesToCheck(deadlineAdvance.timesToCheck)
                        setChecks(deadlineAdvance.checks)
                        checkRefs.current = deadlineAdvance.checks

                        if (deadlineAdvance?.status) {
                            setCompleted(true)
                            return
                        }

                        // console.log("SET SECONDS  " + deadlineAdvance.lastWatched)
                        setCurrentTime(deadlineAdvance.lastWatched)
                        setTimeout(() => {
                            // console.log("SEEK TO " + deadlineAdvance.lastWatched)
                            videoRef.current.seekTo(deadlineAdvance.lastWatched, "seconds")
                        }, 1000)
                    }

                }


            }
            setLoading(false)
        },
        onError: (err) => {
            console.log(err)
            setLoading(false)
        },
        onSettled: () => {
            setLoading(false)
        }
    })


    // console.log(advanceRef.path + "/contents/" + deadline)

    useEffect(() => {
        if (uri) {
            setPlaying(false)
            if (uri.includes("http")) {
                setFile(uri)
            } else if (uri.includes("base64")) {
                setFile(uri)
            } else {
                const fileRef = ref(storage, uri)
                getDownloadURL(fileRef).then(res => {
                    setFile(res)
                }).catch(err => {
                    console.log(err)
                })
            }
        }
    }, [uri])


    const showOverlay = () => {
        if (videoRef.current && !completed && playing) {
            setAreYouThere(true)
            setPlaying(false)
        }
    }

    const updatedLastWatched = (t) => {
        // console.log("UPDATED LAST WATCHED " + t)
        //updated the last watched time on the currentAdvance deadline
        const contentAdvanceRef = doc(db, `${advanceRef?.path}/contents/${deadline}`)
        updateDoc(contentAdvanceRef, {
            lastWatched: t,
            updatedAt: serverTimestamp()
        })
    }

    const startVideo = () => {
        setFirstMessage(false)
        setPlaying(true)

        const contentAdvanceRef = doc(db, `${advanceRef?.path}/contents/${deadline}`)
        setDoc(contentAdvanceRef, {
            ...contentAdvance,
            lastWatched: 0,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            timesToCheck: timesToCheck,
            checks: [],
        }, { merge: true })

        setDeadlineAdvance(contentAdvanceRef)
    }

    const updateCheckedTimes = () => {
        // console.log("UPDATED CHECKED TIMES " + currentTime)
        const contentAdvanceRef = doc(db, `${advanceRef?.path}/contents/${deadline}`)
        updateDoc(contentAdvanceRef, {
            lastWatched: currentTime,
            updatedAt: serverTimestamp(),
            checks: checkRefs.current
        })

        setAreYouThere(false)
        setPlaying(true)
    }

    const restartAdvance = () => {
        const contentAdvanceRef = doc(db, `${advanceRef?.path}/contents/${deadline}`)
        const firstThird = Math.round(duration / numberOfChecks)
        const secondThird = Math.round(duration / numberOfChecks * 2)
        const firstThirdRandom = Math.round(Math.random() * firstThird)
        const secondThirdRandom = Math.round(Math.random() * firstThird) + firstThird
        const thirdThirdRandom = Math.round(Math.random() * firstThird) + secondThird
        const times = [Math.round(firstThirdRandom), Math.round(secondThirdRandom), Math.round(thirdThirdRandom)]
        setTimesToCheck(times)
        setRestartModal(false)
        setCompleted(false)

        setDoc(contentAdvanceRef, {
            ...contentAdvance,
            status: null,
            lastWatched: 0,
            updatedAt: serverTimestamp(),
            timesToCheck: times,
            checks: [],
        }, { merge: true })

    }

    const completeContent = () => {
        const contentAdvanceRef = doc(db, `${advanceRef?.path}/contents/${deadline}`)
        const pass = checkRefs.current.reduce((acc, el) => {
            if (el?.status === true) {
                return acc + 1
            } else {
                return acc
            }
        }, 0) >= 2

        updateDoc(contentAdvanceRef, {
            status: !pass ? "FAILED" : "APPROVED",
            updatedAt: serverTimestamp(),
        })

        videoRef.current.seekTo(0, "seconds")
        setCompleteModal(false)
        setCompleted(true)
    }

    if (loading) {
        return (
            <View style={{ flex: 1, minHeight: 400, alignItems: "center", justifyContent: "center" }}>
                <ActivityIndicator />
            </View>
        )
    }
    return (
        <>
            <View style={{ borderRadius: 15, overflow: "hidden" }}>
                {noDeadlineHasStarted || (!currentDeadline && !noDeadlineHasStarted && !approved)  ?
                    <View style={{ backgroundColor: "rgba(0,0,0,.7)", alignItems: "center", justifyContent: "center", zIndex: 15, position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                        <StyledText style={{ fontSize: 34, color: 'white' }}>
                            El plazo de convocatoria no ha comenzado
                        </StyledText>
                    </View>
                    : null
                }
                {!completed && !noDeadlineHasStarted && (currentDeadline && !noDeadlineHasStarted) ?
                    <>
                        <TouchableOpacity
                            onPress={() => {
                                if (currentTime === 0 && !completed) {
                                    setFirstMessage(true)
                                } else {
                                    setPlaying(!playing)
                                }
                            }}
                            style={[styles.shadow, { zIndex: 10, position: "absolute", alignItems: "center", justifyContent: "center", bottom: 15, left: 15, width: 60, height: 60, borderRadius: 30, backgroundColor: "white" }]}>
                            <Ionicons name={playing ? "pause" : "play"} size={30} />
                        </TouchableOpacity>
                        <View style={{ zIndex: 10, position: "absolute", right: 15, bottom: 15, flexDirection: "row" }}>
                            <StyledText style={{ fontFamily: "TitleWide", fontSize: 18, color: 'white' }}>
                                {secondsToHHMMSS(duration)} - {secondsToHHMMSS(currentTime || 0)}
                            </StyledText>
                        </View>
                        <LinearGradient
                            colors={['rgba(0,0,0,0)', 'rgba(0,0,0,.7)']}
                            style={{ with: "100%", height: 100, position: "absolute", zIndex: 5, bottom: 0, left: 0, right: 0 }} />
                    </>
                    : null

                }
                {areYouThere ?
                    <View style={{ backgroundColor: "rgba(0,0,0,.7)", alignItems: "center", justifyContent: "center", zIndex: 15, position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                        <StyledText style={{ fontSize: 34, color: 'white' }}>
                            Pulsa el botón para confirmar la visualización y continuar el video
                        </StyledText>
                        <CountDown time={90}
                            onEnd={() => {
                                // setAreYouThere(false)
                                // setPlaying(true)
                                const c = [
                                    ...checkRefs.current,
                                    {
                                        time: currentTime,
                                        status: false
                                    }
                                ]
                                checkRefs.current = c
                                setChecks(c)
                                updateCheckedTimes()
                            }} />
                        <TouchableOpacity onPress={() => {

                            const c = [
                                ...checkRefs.current,
                                {
                                    time: currentTime,
                                    status: true
                                }
                            ]
                            checkRefs.current = c
                            setChecks(c)
                            updateCheckedTimes()
                        }} style={{ flexDirection: "row", marginTop: 15, alignItems: "center", justifyContent: "center", borderRadius: 7, paddingHorizontal: 20, paddingVertical: 10, backgroundColor: "white" }}>
                            <StyledText style={{ fontFamily: "TitleWide", marginRight: 5 }}>
                                Continuar video
                            </StyledText>
                            <Ionicons name="play" size={24} />
                        </TouchableOpacity>
                    </View>
                    : null
                }

                {completeModal ?
                    <View style={{ backgroundColor: "rgba(0,0,0,.7)", alignItems: "center", paddingHorizontal: 15, justifyContent: "center", zIndex: 15, position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
                        <StyledText style={{ textAlign: "center", fontSize: 34, color: 'white' }}>
                            Has visualizado todo el contenido, si has marcado los momentos clave, pulsa el botón para completar el contenido, si no puedes reiniciar el intento en la duración de la convocatoria.
                        </StyledText>
                        <TouchableOpacity onPress={() => {
                            completeContent()
                        }} style={{ flexDirection: "row", marginTop: 15, alignItems: "center", justifyContent: "center", borderRadius: 7, paddingHorizontal: 20, paddingVertical: 10, backgroundColor: "white" }}>
                            <StyledText style={{ fontFamily: "TitleWide", marginRight: 5 }}>
                                Completar Contenido
                            </StyledText>
                            <Ionicons name="checkmark-circle-outline" size={24} />
                        </TouchableOpacity>
                    </View>
                    : null
                }


                <ReactPlayer
                    playsinline={true}
                    onDuration={e => {
                        if (!completed) {
                            setDuration(Math.round(e))
                            if (timesToCheck?.length === 0) {
                                //get three random times to check based on numberOfChecks and duration of the video one on the first 1/3 of the video, one on the middle 1/3 and one on the last 1/3
                                const firstThird = Math.round(e / numberOfChecks)
                                const secondThird = Math.round(e / numberOfChecks * 2)
                                const firstThirdRandom = Math.round(Math.random() * firstThird)
                                const secondThirdRandom = Math.round(Math.random() * firstThird) + firstThird
                                const thirdThirdRandom = Math.round(Math.random() * firstThird) + secondThird
                                setTimesToCheck([Math.round(firstThirdRandom), Math.round(secondThirdRandom), Math.round(thirdThirdRandom)])
                            }
                        }
                    }}
                    onEnded={() => setCompleteModal(true)}
                    onProgress={(ev) => {
                        if (!completed) {
                            const isTime = timesToCheck.includes(Math.floor(ev.playedSeconds))
                            const timeIsAlreadyChecked = checkRefs.current.find(x => x.time === Math.floor(ev.playedSeconds))
                            // console.log(isTime, timeIsAlreadyChecked, timesToCheck, checkRefs.current)

                            // update updatedLastWatched function every 10 seconds
                            if (Math.floor(ev.playedSeconds) % 10 === 0 && !isTime && Math.floor(ev.playedSeconds) > 0 && playing) {
                                updatedLastWatched(Math.floor(ev.playedSeconds))
                            }


                            //check if the current time is one of the times to check and if its not already being checked on the checks array
                            if (isTime && !timeIsAlreadyChecked) {
                                // console.log("CHECKING")
                                showOverlay()
                            }

                            if (playing) {
                                setCurrentTime(Math.floor(ev.playedSeconds))
                            }
                        }
                    }}
                    ref={videoRef}
                    width={"100%"}
                    height={"auto"}
                    stopOnUnmount={true}
                    url={file}
                    playing={noDeadlineHasStarted || (!currentDeadline && !noDeadlineHasStarted) && !approved ? false : playing}
                    controls={noDeadlineHasStarted || (!currentDeadline && !noDeadlineHasStarted) && !approved ? false : completed ? true : false}
                />
            </View>
            {allDeadlinesHavePassed || noDeadlineHasStarted || (!currentDeadline && !noDeadlineHasStarted) ? null : <View style={{ marginTop: 15, alignItems: "cemter", flexDirection: "row", justifyContent: "space-between" }}>
                {timesToCheck?.length > 0 ? <View style={{ gap: 10, flexDirection: "row", alignItems: "center" }}>
                    <StyledText style={{ fontFamily: "TitleWide", fontSize: 12 }}>
                        Presencialidad
                    </StyledText>
                    {timesToCheck?.map((x, i) => {
                        return (
                            <View key={i} style={{ width: 20, height: 20, borderRadius: 10, backgroundColor: checks[i] ? checks[i]?.status ? "#8bc34a" : "red" : "gainsboro" }} />
                        )
                    })}

                </View> : <View />}

                {completed && (!deadlineAdvance || deadlineAdvance?.status === "APPROVED" || approved)  ?
                    null :
                    <Button
                        onPress={() => {
                            setPlaying(false)
                            setRestartModal(true)
                        }}
                        label="Reiniciar Intento"
                        round="7px"
                        height="40px"
                    />
                }
            </View>}

            {restartModal ?
                <ModalSure closeCallback={() => setRestartModal(false)} pressCallback={restartAdvance} />
                : null
            }
            {firstMessage ?
                <InfoModal
                    closeCallback={() => {
                        setFirstMessage(false)
                        setPlaying(false)
                    }}
                    playCallback={() => {
                        startVideo()
                    }}
                />
                : null
            }
        </>
    );
}

export default ControledVideo;

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.4,
        shadowRadius: 10,
        elevation: 10,
    }
});


const CountDown = ({ time, onEnd }) => {

    const [seconds, setSeconds] = useState(time)

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => {
                setSeconds(seconds - 1)
            }, 1000)
        } else {
            onEnd()
        }
    }, [seconds])



    return (
        <StyledText style={{ fontSize: 34, color: 'white' }}>
            {secondsToMMSS(seconds)}
        </StyledText>
    );
}


const ModalSure = ({ closeCallback, pressCallback }) => {
    return (
        <Modal
            onClickOutside={closeCallback}
            modalContainerStyle={{
                maxWidth: 500,
            }}
            padding={15}
        >
            <View style={{ rowGap: 10 }}>
                <View style={{ alignItems: "center" }}>
                    <Ionicons name="alert-circle-outline" size={100} color="red" />
                </View>
                <StyledText style={{ fontSize: 18, marginBottom: 15 }}>
                    ¿Estás seguro de que quieres reiniciar el intento? Se perderán todos los datos y tendrás de comenzar el video de nuevo.
                </StyledText>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <Button onPress={closeCallback} label="Cancelar" round="7px" height="40px" color="gray" />
                    <Button label="Confirmar" round="7px" height="40px" onPress={pressCallback} />
                </View>
            </View>
        </Modal>
    )
}
const InfoModal = ({ closeCallback, playCallback }) => {
    return (
        <Modal
            onClickOutside={closeCallback}
            modalContainerStyle={{
                maxWidth: 500,
            }}
            padding={15}
        >
            <View style={{ rowGap: 10 }}>
                <View style={{ alignItems: "center" }}>
                    <Ionicons name="warning-outline" size={100} color="orange" />
                </View>
                <StyledText style={{ fontSize: 18, marginBottom: 15 }}>
                    {/* <Ionicons name="caret-forward" style={{ marginRight: 5 }} size={22} color="green" /> */}
                    A lo largo del video aparecerán 3 mensajes de confirmación de visualización.
                </StyledText>
                <StyledText style={{ fontSize: 18, marginBottom: 15 }}>
                    {/* <Ionicons name="caret-forward" style={{ marginRight: 5 }} size={22} color="green" /> */}
                    Para superar este ejercicio deberás confirmar al menos 2 de las 3 veces que aparezca ese mensaje.
                </StyledText>
                <StyledText style={{ fontSize: 18, marginBottom: 15 }}>
                    {/* <Ionicons name="caret-forward" style={{ marginRight: 5 }} size={22} color="green" /> */}
                    Tendrás 90 segundos para confirmar cada mensaje.
                </StyledText>
                <StyledText style={{ fontSize: 18, marginBottom: 15 }}>
                    {/* <Ionicons name="caret-forward" style={{ marginRight: 5 }} size={22} color="green" /> */}
                    Puedes repetir tantas veces como quieras el ejercicio durante el tiempo disponible en las convocatorias.
                </StyledText>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                    <Button onPress={closeCallback} label="Cancelar" round="7px" height="40px" color="gray" />
                    <Button onPress={playCallback} label="Comenzar" round="7px" height="40px" />
                </View>
            </View>
        </Modal>
    )
}