import Portal from "@burstware/react-native-portal";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as Notifications from "expo-notifications";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { DocumentReference, collection, doc, getCountFromServer, getDoc, getFirestore, query, where } from "firebase/firestore";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Alert, Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import TutorChat from "../components/TutorChat";
import AuthContext from "../context/AuthContext";
import EmailVerification from "../screens/stackLogin/EmailVerification";
import Login from "../screens/stackLogin/Login";
import Lost from "../screens/stackLogin/Lost";
import PasswordReset from "../screens/stackLogin/PasswordReset";
import Register from "../screens/stackLogin/Register";
import Success from "../screens/stackLogin/Success";
import VerifyEmail from "../screens/stackLogin/VerifyEmail";
import MainTabNavigator from "./MainTabNavigator";
import StackEmbedded from "./StackEmbedded";
import StackPublic from "./StackPublic";

import * as Analytics from "firebase/analytics";
import SecureImage from "../components/common/SecureImage";
import StyledText from "../components/common/StyledText";
import customLog from "../utils/customLog";


const Stack = createStackNavigator();

const config = {
	screens: {
		StackEmbedded: {
			path: "embedded",
			screens: {
				Embedded: {
					path: ":tenantId",
				},
			},
		},
		StackPublic: {
			path: "",
			screens: {
				PaymentRedirect: {
					path: "payment-redirect",
				},
				HomeScreen: {
					path: "",
				},
				RfefPrograms: {
					path: "programas-rfef",
				},
				CoachesSchool: {
					path: "escuela-entrenadores",
				},
				SingleCoachesSchool: {
					path: "escuela-entrenadores/:fed",
				},
				UniversityPrograms: {
					path: "programas-universitarios",
				},
				PreviewCourseScreen: {
					path: "/fed/:tenantId/curso/:id/:order?",
				},
				Contact: {
					path: "contacto",
				},
				GlobalPage: {
					path: "page/:slug",
				},
				DownloadPage: {
					path: "adjuntos"
				},
				Process: {
					path: "proceso-solicitud"
				},
				Recognition: {
					path: "reconocimiento-competencias"
				},
				QualityForm: {
					path: "sello-calidad"
				}
			},
		},
		MainTabNavigator: {
			path: "alumno",
			screens: {
				ProfileHome: {
					path: "/mi-perfil"
				},
				LicensesScreen: {
					path: "/licencias"
				},
				CalendarScreen: "/calendario",
				MessagesScreen: "/notificaciones",
				IframeScreen: "/herramientas/:id",
				ToolScreen: "/herramientas",
				SmartHome: "cursos/:order?",
				Reevaluation: "reevaluacion",
				Course: {
					path: "curso/:id/:order?",
				},
				Board: "/board",
				VideoExercises: "/videoexercises",
				EventSet: "/eventset/:id",
				VideoExercise: "/videoexercise/:id",
				Exercises: "/exercises",
				Exercise: "/exercises/:id",
				Sessions: "/sessions/:id",
				CourseForum: {
					path: "curso/:id/forum",
				},
				SingleTopic: {
					path: "curso/:courseID/forum/:id",
				},
				GhostLogin: {
					path: "impersonate/:key"
				}
			}
		},
		Login: "login",
		Register: "register",
		EmailVerification: "verify-email",
		VerifyEmail: "email-verification",
		Success: "success",
		PasswordReset: "reset-password",
		Lost: "lost",
		Loading: "loading",
	}
}

// NAVEGACION
const linking = {
	prefixes: ['onformacion://', 'https://onformacion.rfef.es'],
	config
};


Notifications.setNotificationHandler({
	handleNotification: async () => ({
		shouldShowAlert: true,
		shouldPlaySound: false,
		shouldSetBadge: false,
	}),
});


const customization = {
	logoRatio: 1,
	logo: "https://master.d2ffacdtqumpti.amplifyapp.com/logo-white.png",
	mainLogo: "https://master.d2ffacdtqumpti.amplifyapp.com/logo-white.png",
	placeholder: "https://master.d2ffacdtqumpti.amplifyapp.com/logo-white.png",
	mainColor: "#e4002a",
	accentColor: "#b53a37",
	secondaryColor: "#e4002a",
	headerButtonColor: "white",
	headerElementsColor: "white",
	achievements: [],
}

const theme = {
	...DefaultTheme,
	colors: {
		...DefaultTheme.colors,
		primary: "white",
		background: "ghostwhite",
		card: "rgb(255, 255, 255)",
		text: "rgb(28, 28, 30)",
		border: "rgb(199, 199, 204)",
	},
};


const AppNavigator = () => {

	const routeNameRef = useRef(null);
	const navigationRef = useRef(null);
	const loader = useRef(null);
	const auth = getAuth()
	const db = getFirestore()
	const analytics = Analytics.getAnalytics()

	const user = auth.currentUser

	const [loading, setLoading] = useState(true)
	const [chatVisible, setChatVisible] = useState(false)
	const [userData, setUserData] = useState(null)




	const getNotificationsCount = async (user) => {
		const q = query(collection(db, `users/${user?.uid}/notifications`), where("read", "==", false));
		const snapshot = await getCountFromServer(q);
		const n = snapshot.data().count;
		return n
	}

	const fetchUser = async (user) => {

		let impersonatedUser = localStorage.getItem("impersonatedUser") || null

		if (impersonatedUser && impersonatedUser !== user?.uid && user?.uid) {

			const originalUser = await getDoc(doc(db, "users", user?.uid))
			if (originalUser.exists()) {
				const originalUserData = originalUser.data()
				const tenants = originalUserData?.tenants || []
				const tenantsRoles = await Promise.all(tenants.map(async x => {
					if (x instanceof DocumentReference) {
						const tenant = await getDoc(doc(db, x.path, "users", user?.uid))
						return { id: x.id, ...tenant.data() }
					} else {
						return {}
					}
				}))
				const isAdmin = originalUserData?.superAdmin || tenantsRoles.some(x => x.role === "Admin")
				if (!isAdmin) {
					localStorage.removeItem("impersonatedUser")
					impersonatedUser = null
				}
			} else {
				localStorage.removeItem("impersonatedUser")
				impersonatedUser = null
			}
		} else {
			localStorage.removeItem("impersonatedUser")
			impersonatedUser = null
		}

		const userId = impersonatedUser || user?.uid

		const userRef = doc(db, "users", userId)

		await getDoc(userRef)
			.then(async res => {
				const n = await getNotificationsCount({ ...user, uid: userId })
				const data = res.data() || {}
				setUserData({
					...data,
					...user,
					email: data?.email || user?.email,
					id: res.id,
					uid: userId,
					ref: res.ref,
					notificationsCount: n || 0
				})
			})
	}

	useEffect(() => {
		loader?.current?.open()

		const listener = onAuthStateChanged(auth, async (user) => {
			if (user) {
				await fetchUser(user)
			}
			setLoading(false)
			loader?.current?.close()
		});

		return () => listener();
	}, []);


	const authFunctions = useMemo(() => ({
		signOut: async (data) => {
			signOut(auth)
				.then(async () => {
					localStorage.removeItem("impersonatedUser")
					window.location.reload();
				})
				.catch((error) => {
					Alert.alert(error.message);
				});
		}
	}), []);


	const getActiveRouteName = (state) => {
		const route = state.routes[state.index];
		if (route.state) {
			return getActiveRouteName(route.state);
		}

		return {
			name: route.name,
			id: route.params ? route.params.id : null,
			...route,
		};
	};

	const impersonatedUser = localStorage.getItem('impersonatedUser')

	return (
		<>
			{chatVisible ? <TutorChat key={chatVisible?.name} closeCallback={() => setChatVisible(false)} data={chatVisible} name={chatVisible?.name} mainColor={customization.mainColor} user={userData?.uid} teacher={chatVisible?.teacher?.path ? chatVisible?.teacher?.id : chatVisible?.teacher?.split("/").pop()} courseRef={chatVisible?.courseRef} /> : null}

			<LoadingScreen ref={loader} />

			{loading
				? null
				: <NavigationContainer
					linking={linking}
					fallback={<Text>Loading...</Text>}
					theme={theme}
					ref={navigationRef}
					documentTitle={{ formatter: (options, route) => `${options?.title ?? route?.name} - ON Formación RFEF` }}
					onReady={() => {
						try {
							const route = getActiveRouteName(navigationRef?.current?.getRootState())
							Analytics?.logEvent(analytics, "page_view", { screen_name: route?.name || "APP" })
						} catch (err) {
							console.log(err)
						}
					}}
					onStateChange={(state) => {
						const previousRouteName = routeNameRef.current;
						const currentRouteName = getActiveRouteName(state);
						if (previousRouteName !== currentRouteName.name) {
							Analytics?.logEvent(analytics, "page_view", { screen_name: currentRouteName.name });
							customLog({ screen: currentRouteName.name, path: currentRouteName.path, action: "page_view" })
						}
						routeNameRef.current = currentRouteName.name;
					}}
				>

					<View style={{ flex: 1 }}>
						<AuthContext.Provider value={{
							customization,
							authFunctions,
							chatVisible,
							setChatVisible,
							userData: { ...auth.currentUser, ...(userData || {}) },
							impersonatedUser
							// notificationsCount,
							// getNotificationsCount,
							// setNotificationsCount,
							// dispatchUserData,
							// tenantSelector,
							// setTenantSelector,
							// tenant,
							// logging
							// dimensions
						}}>

							<Portal.Host>
								<Stack.Navigator screenOptions={{ headerMode: "screen", headerShown: false }}>
									<Stack.Screen name="StackPublic" component={StackPublic} />
									{userData && user?.emailVerified && <Stack.Screen name="MainTabNavigator" component={MainTabNavigator} />}
									{!userData && <Stack.Screen name="Login" options={{ title: "Acceso", animationTypeForReplace: user ? "pop" : "push" }} component={Login} />}
									{!userData && <Stack.Screen name="Lost" options={{ title: "Recuperar Contraseña" }} component={Lost} />}
									{!userData && <Stack.Screen name="PasswordReset" options={{ title: "Recuperar Contraseña", animationTypeForReplace: user ? "pop" : "push" }} component={PasswordReset} />}
									{!userData && <Stack.Screen name="Success" options={{ title: "Verificación Completada", animationTypeForReplace: user ? "pop" : "push" }} component={Success} />}
									<Stack.Screen options={{ title: "Registro" }} name="Register" component={Register} />
									<Stack.Screen options={{ title: "Verificar Email" }} name="VerifyEmail" component={VerifyEmail} />
									<Stack.Screen options={{ title: "Verificat Email" }} name="EmailVerification" component={EmailVerification} />
									<Stack.Screen name="StackEmbedded" component={StackEmbedded} />
								</Stack.Navigator>
							</Portal.Host>
						</AuthContext.Provider>
					</View>
				</NavigationContainer>
			}

			{impersonatedUser ?
				<View style={{ position: "fixed", bottom: 0, left: 0, right: 0, paddingHorizontal: 10, flexDirection: "row", gap: 15, alignItems: "center", justifyContent: "center", zIndex: 3, height: 40, backgroundColor: "#fcbb2c", width: "100%" }}>
					<View style={{ flexDirection: "row", alignItems: "center", flex: 1, gap: 10 }}>
						<StyledText style={{ fontWeight: "bold", color: "black", textAlign: "center" }} numberOfLines={1}>
							Estás navegando como {userData?.name} {userData?.surname}
						</StyledText>
						<SecureImage style={{ height: 35, width: 35, borderRadius: 20 }} uri={userData?.image} placeholder={undefined} />
					</View>
					<TouchableOpacity
						onPress={() => {
							localStorage.removeItem("impersonatedUser")
							window.location.reload()
						}}
						style={{
							height: 30,
							paddingHorizontal: 10,
							borderWidth: 2,
							borderRadius: 5,
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<StyledText style={{ color: "black", fontWeight: "bold" }}>Salir</StyledText>
					</TouchableOpacity>

				</View>
				: null
			}
		</>
	);
};

export default AppNavigator;


const LoadingScreen = forwardRef(({ }, ref) => {

	const [visible, setVisible] = useState(false)

	useImperativeHandle(ref, () => ({
		open: () => setVisible(true),
		close: () => setVisible(false)
	}))

	if (!visible) return null
	return (
		<View pointerEvents="none" style={[StyleSheet.absoluteFill, { zIndex: 20, alignItems: "center", justifyContent: 'center', backgroundColor: "#e4002b" }]}>
			<Image style={{ height: 170, width: "50%", resizeMode: "contain" }} source={require("../assets/images/logoon.png")} />
		</View>
	)
})

